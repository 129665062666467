/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  UserCategoriesApi,
  UserCategoriesIndexErrorCodes,
  UserCategoriesIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new UserCategoriesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type UserCategoriesIndexMethod = UserCategoriesApi['userCategoriesIndex'];
export type UserCategoriesIndexResponseType = MethodResult<UserCategoriesIndexMethod>;

export const useUserCategoriesIndex = (
  variables: Parameters<UserCategoriesIndexMethod>[0],
  config?: UseQueryConfig<
    UserCategoriesIndexMethod,
    UserCategoriesIndexErrorTypes
  >
) => {
  return useQuery<
    UserCategoriesIndexMethod,
    UserCategoriesIndexErrorTypes
  >(
    (...args) => api.userCategoriesIndex(...args),
    UserCategoriesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['UserCategoriesIndex'],
        ...apiQueryConfig.UserCategoriesIndex,
        ...config?.queryConfig
      },
    },
    'UserCategoriesIndex',
  );
};

export const useUserCategoriesIndexInfinite = (
  variables: Parameters<UserCategoriesIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    UserCategoriesIndexMethod,
    UserCategoriesIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    UserCategoriesIndexMethod,
    UserCategoriesIndexErrorTypes
  >(
    (...args) => api.userCategoriesIndex(...args),
    UserCategoriesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['UserCategoriesIndex', 'UserCategoriesIndexInfinite'],
        ...apiQueryConfig.UserCategoriesIndex,
        ...config?.queryConfig,
      },
    },
    'UserCategoriesIndex',
  );
};

export const useUserCategoriesIndexLazy = (baseOptions?: {
  variables?: Parameters<UserCategoriesIndexMethod>[0],
  config?: UseQueryConfig<
    UserCategoriesIndexMethod,
    UserCategoriesIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    UserCategoriesIndexMethod,
    UserCategoriesIndexErrorTypes
  >(
    (...args) => api.userCategoriesIndex(...args),
    UserCategoriesIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['UserCategoriesIndex'],
        ...apiQueryConfig.UserCategoriesIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'UserCategoriesIndex',
  );
};

