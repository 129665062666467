/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  SubmissionApi,
  EventSubmissionIndexErrorCodes,
  EventSubmissionIndexErrorTypes,
  EventSubmissionCreateErrorCodes,
  EventSubmissionShowErrorCodes,
  EventSubmissionShowErrorTypes,
  EventSubmissionUpdateErrorCodes,
  EventSubmissionDeleteErrorCodes,
  SecondmarketV2SubmissionIndexErrorCodes,
  SecondmarketV2SubmissionIndexErrorTypes,
  SecondmarketV2SubmissionCreateErrorCodes,
  SecondmarketV2SubmissionShowErrorCodes,
  SecondmarketV2SubmissionShowErrorTypes,
  SecondmarketSubmissionIndexErrorCodes,
  SecondmarketSubmissionIndexErrorTypes,
  SecondmarketSubmissionCreateErrorCodes,
  SecondmarketSubmissionShowErrorCodes,
  SecondmarketSubmissionShowErrorTypes,
  SubmissionIndexErrorCodes,
  SubmissionIndexErrorTypes,
  SubmissionCreateErrorCodes,
  SubmissionsDownloadIndexErrorCodes,
  SubmissionsDownloadIndexErrorTypes,
  SubmissionShowErrorCodes,
  SubmissionShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new SubmissionApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type EventSubmissionIndexMethod = SubmissionApi['eventSubmissionIndex'];
export type EventSubmissionIndexResponseType = MethodResult<EventSubmissionIndexMethod>;

export const useEventSubmissionIndex = (
  variables: Parameters<EventSubmissionIndexMethod>[0],
  config?: UseQueryConfig<
    EventSubmissionIndexMethod,
    EventSubmissionIndexErrorTypes
  >
) => {
  return useQuery<
    EventSubmissionIndexMethod,
    EventSubmissionIndexErrorTypes
  >(
    (...args) => api.eventSubmissionIndex(...args),
    EventSubmissionIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventSubmissionIndex'],
        ...apiQueryConfig.EventSubmissionIndex,
        ...config?.queryConfig
      },
    },
    'EventSubmissionIndex',
  );
};

export const useEventSubmissionIndexInfinite = (
  variables: Parameters<EventSubmissionIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    EventSubmissionIndexMethod,
    EventSubmissionIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    EventSubmissionIndexMethod,
    EventSubmissionIndexErrorTypes
  >(
    (...args) => api.eventSubmissionIndex(...args),
    EventSubmissionIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventSubmissionIndex', 'EventSubmissionIndexInfinite'],
        ...apiQueryConfig.EventSubmissionIndex,
        ...config?.queryConfig,
      },
    },
    'EventSubmissionIndex',
  );
};

export const useEventSubmissionIndexLazy = (baseOptions?: {
  variables?: Parameters<EventSubmissionIndexMethod>[0],
  config?: UseQueryConfig<
    EventSubmissionIndexMethod,
    EventSubmissionIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    EventSubmissionIndexMethod,
    EventSubmissionIndexErrorTypes
  >(
    (...args) => api.eventSubmissionIndex(...args),
    EventSubmissionIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['EventSubmissionIndex'],
        ...apiQueryConfig.EventSubmissionIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'EventSubmissionIndex',
  );
};


export type EventSubmissionCreateMethod = SubmissionApi['eventSubmissionCreate'];

export const useEventSubmissionCreate = () => {
  return useMutation<EventSubmissionCreateMethod>(
    (...args) => api.eventSubmissionCreate(...args),
    EventSubmissionCreateErrorCodes,
    'EventSubmissionCreate',
    '/api/events/{event_id}/submissions',
    'post'
  );
};

export type EventSubmissionShowMethod = SubmissionApi['eventSubmissionShow'];
export type EventSubmissionShowResponseType = MethodResult<EventSubmissionShowMethod>;

export const useEventSubmissionShow = (
  variables: Parameters<EventSubmissionShowMethod>[0],
  config?: UseQueryConfig<
    EventSubmissionShowMethod,
    EventSubmissionShowErrorTypes
  >
) => {
  return useQuery<
    EventSubmissionShowMethod,
    EventSubmissionShowErrorTypes
  >(
    (...args) => api.eventSubmissionShow(...args),
    EventSubmissionShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventSubmissionShow'],
        ...apiQueryConfig.EventSubmissionShow,
        ...config?.queryConfig
      },
    },
    'EventSubmissionShow',
  );
};

export const useEventSubmissionShowInfinite = (
  variables: Parameters<EventSubmissionShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    EventSubmissionShowMethod,
    EventSubmissionShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    EventSubmissionShowMethod,
    EventSubmissionShowErrorTypes
  >(
    (...args) => api.eventSubmissionShow(...args),
    EventSubmissionShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventSubmissionShow', 'EventSubmissionShowInfinite'],
        ...apiQueryConfig.EventSubmissionShow,
        ...config?.queryConfig,
      },
    },
    'EventSubmissionShow',
  );
};

export const useEventSubmissionShowLazy = (baseOptions?: {
  variables?: Parameters<EventSubmissionShowMethod>[0],
  config?: UseQueryConfig<
    EventSubmissionShowMethod,
    EventSubmissionShowErrorTypes
  >
}) => {
  return useLazyQuery<
    EventSubmissionShowMethod,
    EventSubmissionShowErrorTypes
  >(
    (...args) => api.eventSubmissionShow(...args),
    EventSubmissionShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['EventSubmissionShow'],
        ...apiQueryConfig.EventSubmissionShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'EventSubmissionShow',
  );
};


export type EventSubmissionUpdateMethod = SubmissionApi['eventSubmissionUpdate'];

export const useEventSubmissionUpdate = () => {
  return useMutation<EventSubmissionUpdateMethod>(
    (...args) => api.eventSubmissionUpdate(...args),
    EventSubmissionUpdateErrorCodes,
    'EventSubmissionUpdate',
    '/api/events/{event_id}/submissions/{submission_id}',
    'put'
  );
};

export type EventSubmissionDeleteMethod = SubmissionApi['eventSubmissionDelete'];

export const useEventSubmissionDelete = () => {
  return useMutation<EventSubmissionDeleteMethod>(
    (...args) => api.eventSubmissionDelete(...args),
    EventSubmissionDeleteErrorCodes,
    'EventSubmissionDelete',
    '/api/events/{event_id}/submissions/{submission_id}',
    'delete'
  );
};

export type SecondmarketV2SubmissionIndexMethod = SubmissionApi['secondmarketV2SubmissionIndex'];
export type SecondmarketV2SubmissionIndexResponseType = MethodResult<SecondmarketV2SubmissionIndexMethod>;

export const useSecondmarketV2SubmissionIndex = (
  variables: Parameters<SecondmarketV2SubmissionIndexMethod>[0],
  config?: UseQueryConfig<
    SecondmarketV2SubmissionIndexMethod,
    SecondmarketV2SubmissionIndexErrorTypes
  >
) => {
  return useQuery<
    SecondmarketV2SubmissionIndexMethod,
    SecondmarketV2SubmissionIndexErrorTypes
  >(
    (...args) => api.secondmarketV2SubmissionIndex(...args),
    SecondmarketV2SubmissionIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecondmarketV2SubmissionIndex'],
        ...apiQueryConfig.SecondmarketV2SubmissionIndex,
        ...config?.queryConfig
      },
    },
    'SecondmarketV2SubmissionIndex',
  );
};

export const useSecondmarketV2SubmissionIndexInfinite = (
  variables: Parameters<SecondmarketV2SubmissionIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    SecondmarketV2SubmissionIndexMethod,
    SecondmarketV2SubmissionIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    SecondmarketV2SubmissionIndexMethod,
    SecondmarketV2SubmissionIndexErrorTypes
  >(
    (...args) => api.secondmarketV2SubmissionIndex(...args),
    SecondmarketV2SubmissionIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecondmarketV2SubmissionIndex', 'SecondmarketV2SubmissionIndexInfinite'],
        ...apiQueryConfig.SecondmarketV2SubmissionIndex,
        ...config?.queryConfig,
      },
    },
    'SecondmarketV2SubmissionIndex',
  );
};

export const useSecondmarketV2SubmissionIndexLazy = (baseOptions?: {
  variables?: Parameters<SecondmarketV2SubmissionIndexMethod>[0],
  config?: UseQueryConfig<
    SecondmarketV2SubmissionIndexMethod,
    SecondmarketV2SubmissionIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    SecondmarketV2SubmissionIndexMethod,
    SecondmarketV2SubmissionIndexErrorTypes
  >(
    (...args) => api.secondmarketV2SubmissionIndex(...args),
    SecondmarketV2SubmissionIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['SecondmarketV2SubmissionIndex'],
        ...apiQueryConfig.SecondmarketV2SubmissionIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'SecondmarketV2SubmissionIndex',
  );
};


export type SecondmarketV2SubmissionCreateMethod = SubmissionApi['secondmarketV2SubmissionCreate'];

export const useSecondmarketV2SubmissionCreate = () => {
  return useMutation<SecondmarketV2SubmissionCreateMethod>(
    (...args) => api.secondmarketV2SubmissionCreate(...args),
    SecondmarketV2SubmissionCreateErrorCodes,
    'SecondmarketV2SubmissionCreate',
    '/api/secondmarket-v2/submissions',
    'post'
  );
};

export type SecondmarketV2SubmissionShowMethod = SubmissionApi['secondmarketV2SubmissionShow'];
export type SecondmarketV2SubmissionShowResponseType = MethodResult<SecondmarketV2SubmissionShowMethod>;

export const useSecondmarketV2SubmissionShow = (
  variables: Parameters<SecondmarketV2SubmissionShowMethod>[0],
  config?: UseQueryConfig<
    SecondmarketV2SubmissionShowMethod,
    SecondmarketV2SubmissionShowErrorTypes
  >
) => {
  return useQuery<
    SecondmarketV2SubmissionShowMethod,
    SecondmarketV2SubmissionShowErrorTypes
  >(
    (...args) => api.secondmarketV2SubmissionShow(...args),
    SecondmarketV2SubmissionShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecondmarketV2SubmissionShow'],
        ...apiQueryConfig.SecondmarketV2SubmissionShow,
        ...config?.queryConfig
      },
    },
    'SecondmarketV2SubmissionShow',
  );
};

export const useSecondmarketV2SubmissionShowInfinite = (
  variables: Parameters<SecondmarketV2SubmissionShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    SecondmarketV2SubmissionShowMethod,
    SecondmarketV2SubmissionShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    SecondmarketV2SubmissionShowMethod,
    SecondmarketV2SubmissionShowErrorTypes
  >(
    (...args) => api.secondmarketV2SubmissionShow(...args),
    SecondmarketV2SubmissionShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecondmarketV2SubmissionShow', 'SecondmarketV2SubmissionShowInfinite'],
        ...apiQueryConfig.SecondmarketV2SubmissionShow,
        ...config?.queryConfig,
      },
    },
    'SecondmarketV2SubmissionShow',
  );
};

export const useSecondmarketV2SubmissionShowLazy = (baseOptions?: {
  variables?: Parameters<SecondmarketV2SubmissionShowMethod>[0],
  config?: UseQueryConfig<
    SecondmarketV2SubmissionShowMethod,
    SecondmarketV2SubmissionShowErrorTypes
  >
}) => {
  return useLazyQuery<
    SecondmarketV2SubmissionShowMethod,
    SecondmarketV2SubmissionShowErrorTypes
  >(
    (...args) => api.secondmarketV2SubmissionShow(...args),
    SecondmarketV2SubmissionShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['SecondmarketV2SubmissionShow'],
        ...apiQueryConfig.SecondmarketV2SubmissionShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'SecondmarketV2SubmissionShow',
  );
};


export type SecondmarketSubmissionIndexMethod = SubmissionApi['secondmarketSubmissionIndex'];
export type SecondmarketSubmissionIndexResponseType = MethodResult<SecondmarketSubmissionIndexMethod>;

export const useSecondmarketSubmissionIndex = (
  variables: Parameters<SecondmarketSubmissionIndexMethod>[0],
  config?: UseQueryConfig<
    SecondmarketSubmissionIndexMethod,
    SecondmarketSubmissionIndexErrorTypes
  >
) => {
  return useQuery<
    SecondmarketSubmissionIndexMethod,
    SecondmarketSubmissionIndexErrorTypes
  >(
    (...args) => api.secondmarketSubmissionIndex(...args),
    SecondmarketSubmissionIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecondmarketSubmissionIndex'],
        ...apiQueryConfig.SecondmarketSubmissionIndex,
        ...config?.queryConfig
      },
    },
    'SecondmarketSubmissionIndex',
  );
};

export const useSecondmarketSubmissionIndexInfinite = (
  variables: Parameters<SecondmarketSubmissionIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    SecondmarketSubmissionIndexMethod,
    SecondmarketSubmissionIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    SecondmarketSubmissionIndexMethod,
    SecondmarketSubmissionIndexErrorTypes
  >(
    (...args) => api.secondmarketSubmissionIndex(...args),
    SecondmarketSubmissionIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecondmarketSubmissionIndex', 'SecondmarketSubmissionIndexInfinite'],
        ...apiQueryConfig.SecondmarketSubmissionIndex,
        ...config?.queryConfig,
      },
    },
    'SecondmarketSubmissionIndex',
  );
};

export const useSecondmarketSubmissionIndexLazy = (baseOptions?: {
  variables?: Parameters<SecondmarketSubmissionIndexMethod>[0],
  config?: UseQueryConfig<
    SecondmarketSubmissionIndexMethod,
    SecondmarketSubmissionIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    SecondmarketSubmissionIndexMethod,
    SecondmarketSubmissionIndexErrorTypes
  >(
    (...args) => api.secondmarketSubmissionIndex(...args),
    SecondmarketSubmissionIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['SecondmarketSubmissionIndex'],
        ...apiQueryConfig.SecondmarketSubmissionIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'SecondmarketSubmissionIndex',
  );
};


export type SecondmarketSubmissionCreateMethod = SubmissionApi['secondmarketSubmissionCreate'];

export const useSecondmarketSubmissionCreate = () => {
  return useMutation<SecondmarketSubmissionCreateMethod>(
    (...args) => api.secondmarketSubmissionCreate(...args),
    SecondmarketSubmissionCreateErrorCodes,
    'SecondmarketSubmissionCreate',
    '/api/secondmarket/submissions',
    'post'
  );
};

export type SecondmarketSubmissionShowMethod = SubmissionApi['secondmarketSubmissionShow'];
export type SecondmarketSubmissionShowResponseType = MethodResult<SecondmarketSubmissionShowMethod>;

export const useSecondmarketSubmissionShow = (
  variables: Parameters<SecondmarketSubmissionShowMethod>[0],
  config?: UseQueryConfig<
    SecondmarketSubmissionShowMethod,
    SecondmarketSubmissionShowErrorTypes
  >
) => {
  return useQuery<
    SecondmarketSubmissionShowMethod,
    SecondmarketSubmissionShowErrorTypes
  >(
    (...args) => api.secondmarketSubmissionShow(...args),
    SecondmarketSubmissionShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecondmarketSubmissionShow'],
        ...apiQueryConfig.SecondmarketSubmissionShow,
        ...config?.queryConfig
      },
    },
    'SecondmarketSubmissionShow',
  );
};

export const useSecondmarketSubmissionShowInfinite = (
  variables: Parameters<SecondmarketSubmissionShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    SecondmarketSubmissionShowMethod,
    SecondmarketSubmissionShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    SecondmarketSubmissionShowMethod,
    SecondmarketSubmissionShowErrorTypes
  >(
    (...args) => api.secondmarketSubmissionShow(...args),
    SecondmarketSubmissionShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecondmarketSubmissionShow', 'SecondmarketSubmissionShowInfinite'],
        ...apiQueryConfig.SecondmarketSubmissionShow,
        ...config?.queryConfig,
      },
    },
    'SecondmarketSubmissionShow',
  );
};

export const useSecondmarketSubmissionShowLazy = (baseOptions?: {
  variables?: Parameters<SecondmarketSubmissionShowMethod>[0],
  config?: UseQueryConfig<
    SecondmarketSubmissionShowMethod,
    SecondmarketSubmissionShowErrorTypes
  >
}) => {
  return useLazyQuery<
    SecondmarketSubmissionShowMethod,
    SecondmarketSubmissionShowErrorTypes
  >(
    (...args) => api.secondmarketSubmissionShow(...args),
    SecondmarketSubmissionShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['SecondmarketSubmissionShow'],
        ...apiQueryConfig.SecondmarketSubmissionShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'SecondmarketSubmissionShow',
  );
};


export type SubmissionIndexMethod = SubmissionApi['submissionIndex'];
export type SubmissionIndexResponseType = MethodResult<SubmissionIndexMethod>;

export const useSubmissionIndex = (
  variables: Parameters<SubmissionIndexMethod>[0],
  config?: UseQueryConfig<
    SubmissionIndexMethod,
    SubmissionIndexErrorTypes
  >
) => {
  return useQuery<
    SubmissionIndexMethod,
    SubmissionIndexErrorTypes
  >(
    (...args) => api.submissionIndex(...args),
    SubmissionIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SubmissionIndex'],
        ...apiQueryConfig.SubmissionIndex,
        ...config?.queryConfig
      },
    },
    'SubmissionIndex',
  );
};

export const useSubmissionIndexInfinite = (
  variables: Parameters<SubmissionIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    SubmissionIndexMethod,
    SubmissionIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    SubmissionIndexMethod,
    SubmissionIndexErrorTypes
  >(
    (...args) => api.submissionIndex(...args),
    SubmissionIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SubmissionIndex', 'SubmissionIndexInfinite'],
        ...apiQueryConfig.SubmissionIndex,
        ...config?.queryConfig,
      },
    },
    'SubmissionIndex',
  );
};

export const useSubmissionIndexLazy = (baseOptions?: {
  variables?: Parameters<SubmissionIndexMethod>[0],
  config?: UseQueryConfig<
    SubmissionIndexMethod,
    SubmissionIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    SubmissionIndexMethod,
    SubmissionIndexErrorTypes
  >(
    (...args) => api.submissionIndex(...args),
    SubmissionIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['SubmissionIndex'],
        ...apiQueryConfig.SubmissionIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'SubmissionIndex',
  );
};


export type SubmissionCreateMethod = SubmissionApi['submissionCreate'];

export const useSubmissionCreate = () => {
  return useMutation<SubmissionCreateMethod>(
    (...args) => api.submissionCreate(...args),
    SubmissionCreateErrorCodes,
    'SubmissionCreate',
    '/api/submissions',
    'post'
  );
};

export type SubmissionsDownloadIndexMethod = SubmissionApi['submissionsDownloadIndex'];
export type SubmissionsDownloadIndexResponseType = MethodResult<SubmissionsDownloadIndexMethod>;

export const useSubmissionsDownloadIndex = (
  variables: Parameters<SubmissionsDownloadIndexMethod>[0],
  config?: UseQueryConfig<
    SubmissionsDownloadIndexMethod,
    SubmissionsDownloadIndexErrorTypes
  >
) => {
  return useQuery<
    SubmissionsDownloadIndexMethod,
    SubmissionsDownloadIndexErrorTypes
  >(
    (...args) => api.submissionsDownloadIndex(...args),
    SubmissionsDownloadIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SubmissionsDownloadIndex'],
        ...apiQueryConfig.SubmissionsDownloadIndex,
        ...config?.queryConfig
      },
    },
    'SubmissionsDownloadIndex',
  );
};

export const useSubmissionsDownloadIndexInfinite = (
  variables: Parameters<SubmissionsDownloadIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    SubmissionsDownloadIndexMethod,
    SubmissionsDownloadIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    SubmissionsDownloadIndexMethod,
    SubmissionsDownloadIndexErrorTypes
  >(
    (...args) => api.submissionsDownloadIndex(...args),
    SubmissionsDownloadIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SubmissionsDownloadIndex', 'SubmissionsDownloadIndexInfinite'],
        ...apiQueryConfig.SubmissionsDownloadIndex,
        ...config?.queryConfig,
      },
    },
    'SubmissionsDownloadIndex',
  );
};

export const useSubmissionsDownloadIndexLazy = (baseOptions?: {
  variables?: Parameters<SubmissionsDownloadIndexMethod>[0],
  config?: UseQueryConfig<
    SubmissionsDownloadIndexMethod,
    SubmissionsDownloadIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    SubmissionsDownloadIndexMethod,
    SubmissionsDownloadIndexErrorTypes
  >(
    (...args) => api.submissionsDownloadIndex(...args),
    SubmissionsDownloadIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['SubmissionsDownloadIndex'],
        ...apiQueryConfig.SubmissionsDownloadIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'SubmissionsDownloadIndex',
  );
};


export type SubmissionShowMethod = SubmissionApi['submissionShow'];
export type SubmissionShowResponseType = MethodResult<SubmissionShowMethod>;

export const useSubmissionShow = (
  variables: Parameters<SubmissionShowMethod>[0],
  config?: UseQueryConfig<
    SubmissionShowMethod,
    SubmissionShowErrorTypes
  >
) => {
  return useQuery<
    SubmissionShowMethod,
    SubmissionShowErrorTypes
  >(
    (...args) => api.submissionShow(...args),
    SubmissionShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SubmissionShow'],
        ...apiQueryConfig.SubmissionShow,
        ...config?.queryConfig
      },
    },
    'SubmissionShow',
  );
};

export const useSubmissionShowInfinite = (
  variables: Parameters<SubmissionShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    SubmissionShowMethod,
    SubmissionShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    SubmissionShowMethod,
    SubmissionShowErrorTypes
  >(
    (...args) => api.submissionShow(...args),
    SubmissionShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SubmissionShow', 'SubmissionShowInfinite'],
        ...apiQueryConfig.SubmissionShow,
        ...config?.queryConfig,
      },
    },
    'SubmissionShow',
  );
};

export const useSubmissionShowLazy = (baseOptions?: {
  variables?: Parameters<SubmissionShowMethod>[0],
  config?: UseQueryConfig<
    SubmissionShowMethod,
    SubmissionShowErrorTypes
  >
}) => {
  return useLazyQuery<
    SubmissionShowMethod,
    SubmissionShowErrorTypes
  >(
    (...args) => api.submissionShow(...args),
    SubmissionShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['SubmissionShow'],
        ...apiQueryConfig.SubmissionShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'SubmissionShow',
  );
};

