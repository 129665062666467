/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HoldingsDistributionCalculationCreateRequestContract } from '../model';
// @ts-ignore
import { HoldingsDistributionCalculationShow } from '../model';
/**
 * HoldingsDistributionCalculationApi - axios parameter creator
 * @export
 */
export const HoldingsDistributionCalculationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary generates a distribution calculation for the holdings
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {HoldingsDistributionCalculationCreateRequestContract} [holdingsDistributionCalculationCreateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdingsDistributionCalculationCreate: async (xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, holdingsDistributionCalculationCreateRequestContract?: HoldingsDistributionCalculationCreateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/holding-distribution-calculation`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(holdingsDistributionCalculationCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HoldingsDistributionCalculationApi - functional programming interface
 * @export
 */
export const HoldingsDistributionCalculationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HoldingsDistributionCalculationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary generates a distribution calculation for the holdings
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {HoldingsDistributionCalculationCreateRequestContract} [holdingsDistributionCalculationCreateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holdingsDistributionCalculationCreate(xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, holdingsDistributionCalculationCreateRequestContract?: HoldingsDistributionCalculationCreateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoldingsDistributionCalculationShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holdingsDistributionCalculationCreate(xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, holdingsDistributionCalculationCreateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HoldingsDistributionCalculationApi - factory interface
 * @export
 */
export const HoldingsDistributionCalculationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HoldingsDistributionCalculationApiFp(configuration)
    return {
        /**
         * 
         * @summary generates a distribution calculation for the holdings
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {HoldingsDistributionCalculationCreateRequestContract} [holdingsDistributionCalculationCreateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdingsDistributionCalculationCreate(xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, holdingsDistributionCalculationCreateRequestContract?: HoldingsDistributionCalculationCreateRequestContract, options?: any): AxiosPromise<HoldingsDistributionCalculationShow> {
            return localVarFp.holdingsDistributionCalculationCreate(xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, holdingsDistributionCalculationCreateRequestContract, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for holdingsDistributionCalculationCreate operation in HoldingsDistributionCalculationApi.
 * @export
 * @interface HoldingsDistributionCalculationApiHoldingsDistributionCalculationCreateRequest
 */
export interface HoldingsDistributionCalculationApiHoldingsDistributionCalculationCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof HoldingsDistributionCalculationApiHoldingsDistributionCalculationCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingsDistributionCalculationApiHoldingsDistributionCalculationCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingsDistributionCalculationApiHoldingsDistributionCalculationCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingsDistributionCalculationApiHoldingsDistributionCalculationCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingsDistributionCalculationApiHoldingsDistributionCalculationCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingsDistributionCalculationApiHoldingsDistributionCalculationCreate
     */
    readonly xUserRoleId?: string

    /**
     * 
     * @type {HoldingsDistributionCalculationCreateRequestContract}
     * @memberof HoldingsDistributionCalculationApiHoldingsDistributionCalculationCreate
     */
    readonly holdingsDistributionCalculationCreateRequestContract?: HoldingsDistributionCalculationCreateRequestContract
}

/**
 * HoldingsDistributionCalculationApi - object-oriented interface
 * @export
 * @class HoldingsDistributionCalculationApi
 * @extends {BaseAPI}
 */
export class HoldingsDistributionCalculationApi extends BaseAPI {
    /**
     * 
     * @summary generates a distribution calculation for the holdings
     * @param {HoldingsDistributionCalculationApiHoldingsDistributionCalculationCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoldingsDistributionCalculationApi
     */
    public holdingsDistributionCalculationCreate(requestParameters: HoldingsDistributionCalculationApiHoldingsDistributionCalculationCreateRequest = {}, options?: AxiosRequestConfig) {
        return HoldingsDistributionCalculationApiFp(this.configuration).holdingsDistributionCalculationCreate(requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, requestParameters.holdingsDistributionCalculationCreateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }
}

export type HoldingsDistributionCalculationCreateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const HoldingsDistributionCalculationCreateErrorCodes = [
];


