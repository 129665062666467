import { Divider as AntDivider } from 'antd';
import styled, { type Variant } from 'styled-components';

export const Divider = styled(AntDivider)<{
  $variant: Variant;
  $marginBottom?: number;
  $marginTop?: number;
}>`
  background-color: ${({ theme, $variant }) =>
    theme.color.general.borders[$variant]};
  margin: ${({
    type,
    theme,
    $marginBottom = type === 'vertical' ? 0 : theme.spacing.md,
    $marginTop = 0,
  }) => `${$marginTop}px 0 ${$marginBottom}px 0`};
  ${({ type }) =>
    type === 'vertical' &&
    `
    height: unset;
    align-self: stretch;
  `}
`;
