import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Chart } from 'chart.js';

import { CardBase } from '@npm/core/ui/components/atoms/CardBase';
import {
  CrosshairPlugin,
  useChartOptions,
} from '@npm/core/ui/components/atoms/Charts';
import { type XY } from '@npm/core/ui/components/atoms/ChartTooltip/ChartTooltip.types';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';

import type { BidOfferData } from '../../BidOfferHistory/components/BidOfferHistoryChart/BidOfferHistoryChart.types';
import { useCompanyOverviewChartFilter } from '../../CompanyOverview.hooks';
import type { CompanyOverviewChartSeries } from '../../CompanyOverview.types';
import {
  getCompanyOverviewXScaleConfig,
  getCompanyOverviewYScaleConfig,
  getMinimumDate,
  sortArrayByDateField,
} from '../../CompanyOverview.utils';
import { CompanyOverviewChartContainer } from '../../components/CompanyOverviewChartContainer';
import { CompanyOverviewChartFilters } from '../../components/CompanyOverviewChartFilters/CompanyOverviewChartFilters';
import { type MovingAverageValue } from '../../MutualFundMarks/components/MutualFundMarksChart/MutualFundMarksChart.types';
import { type PrimaryRoundValue } from '../../PrimaryRounds/components/PrimaryRoundsChart/PrimaryRoundsChart.types';
import { sortPrimaryRoundsData } from '../../PrimaryRounds/components/PrimaryRoundsTable/PrimaryRounds.utils';
import { type TapeDValue } from '../../TapeD/TapeD.types';
import { type Valuation409ADataValue } from '../../Valuations409A/Valuations409AChart/Valuations409AChart.types';
import { sortValuations409AData } from '../../Valuations409A/Valuations409AChart/Valuations409AChart.utils';

import {
  useSummaryChartDataSets,
  useSummaryChartTooltipData,
} from './SummaryChart.hooks';
import {
  type CompanyOverviewSummaryChartDataPoint,
  type CompanyOverviewSummaryChartDataSetKey,
  type CompanyOverviewSummaryChartSortedData,
} from './SummaryChart.types';
import { getSummaryChartTooltip } from './SummaryChart.utils';
import { SummaryChartLegend } from './SummaryChartLegend';
import { SummaryChartTooltip } from './SummaryChartTooltip';

type Props = {
  tapeDData: TapeDValue[] | undefined;
  bidOfferData: BidOfferData | undefined;
  showClosedTrades: boolean;
  primaryRoundsData: PrimaryRoundValue[] | undefined;
  valuation409AData: Valuation409ADataValue[];
  movingAverageData: MovingAverageValue[];
};

export const SummaryChart = ({
  tapeDData,
  bidOfferData,
  showClosedTrades,
  primaryRoundsData,
  valuation409AData,
  movingAverageData,
}: Props) => {
  const [hoveredDataSet, setHoveredDataSet] = useState<
    CompanyOverviewSummaryChartDataSetKey | undefined
  >(undefined);

  const [hoveredDate, setHoveredDate] = useState<string | undefined>(undefined);
  const [mouse, setMouse] = useState<XY>({ x: 0, y: 0 });

  // Dark-mode context used to fix issues with switching between dark and light mode
  const { isDarkMode } = useDarkModeContext();

  const sortedData: CompanyOverviewSummaryChartSortedData = useMemo(() => {
    return {
      tapeDData: sortArrayByDateField(tapeDData),
      bidsData: sortArrayByDateField(bidOfferData?.aggregated_bids),
      asksData: sortArrayByDateField(bidOfferData?.aggregated_asks),
      tradesData: showClosedTrades
        ? sortArrayByDateField(bidOfferData?.aggregated_trades)
        : [],
      primaryRoundsData: sortPrimaryRoundsData(primaryRoundsData),
      valuations409AData: sortValuations409AData(valuation409AData),
      movingAverageData: sortArrayByDateField(movingAverageData),
    };
  }, [
    tapeDData,
    bidOfferData,
    showClosedTrades,
    primaryRoundsData,
    valuation409AData,
    movingAverageData,
  ]);

  const [series, setSeries] = useState<CompanyOverviewChartSeries>('PPS');

  const [minDate, maxDate] = useMemo(
    () => [
      getMinimumDate(
        sortedData.asksData,
        sortedData.bidsData,
        sortedData.tradesData,
        sortedData.tapeDData,
        sortedData.primaryRoundsData.map(({ investment_date }) => ({
          date: investment_date,
        }))
      ),
      new Date(),
    ],
    [sortedData]
  );

  const {
    setCurrentPage,
    currentMinDate,
    currentMaxDate,
    currentPage,
    pageCount,
    range,
    setRange,
  } = useCompanyOverviewChartFilter(minDate, maxDate);

  const datasetsMap = useSummaryChartDataSets(
    sortedData,
    series,
    currentMinDate,
    currentMaxDate,
    hoveredDataSet
  );

  const datasets = useMemo(() => {
    return [
      datasetsMap.tapeDDataset,
      datasetsMap.bidsDataset,
      datasetsMap.tradesDataset,
      datasetsMap.offersDataset,
      datasetsMap.primaryRoundsDataset,
      series === 'PPS' ? datasetsMap.valuations409ADataset : undefined,
      datasetsMap.movingAverageDataset,
    ].filter(Boolean);
  }, [datasetsMap, series]);

  const options = useChartOptions();

  const canvasRef = useRef<HTMLCanvasElement>();

  const chartRef =
    useRef<Chart<'line', CompanyOverviewSummaryChartDataPoint[], string>>();

  const destroyChart = useCallback(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
      chartRef.current = null;
    }
  }, []);

  const resetTooltip = useCallback(() => {
    if (!chartRef.current) return;

    getSummaryChartTooltip(chartRef.current?.canvas).style.opacity = '0';
    chartRef.current.update('none');
  }, []);

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');

    chartRef.current = new Chart<
      'line',
      CompanyOverviewSummaryChartDataPoint[],
      string
    >(ctx, {
      type: 'line',
      data: {
        datasets,
      },
      options: options({
        animation: false,
        interaction: {
          mode: 'x',
          intersect: false,
        },
        scales: {
          x: getCompanyOverviewXScaleConfig(currentMinDate, currentMaxDate),
          y: getCompanyOverviewYScaleConfig(),
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      }),
      plugins: [CrosshairPlugin],
    });
    chartRef.current.update('none');

    return () => destroyChart();
  }, [
    datasets,
    destroyChart,
    isDarkMode,
    options,
    currentMinDate,
    currentMaxDate,
  ]);

  const handleMouseMove: React.MouseEventHandler<HTMLCanvasElement> =
    useCallback(event => {
      const rect = chartRef.current.canvas.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;

      const xScale = chartRef.current.scales.x;
      const xValue = xScale.getValueForPixel(mouseX);

      if (
        (xScale.min && xValue < xScale.min) ||
        (xScale.max && xValue > xScale.max)
      ) {
        return;
      }

      const xValueDate = new Date(xValue).toISOString().split('T')[0];

      setHoveredDate(xValueDate);
      setMouse({ x: mouseX, y: mouseY });

      const tooltipEl = getSummaryChartTooltip(chartRef.current?.canvas);
      tooltipEl.style.display = 'block';
      tooltipEl.style.opacity = '1';
    }, []);

  const handleMouseLeave = useCallback(() => {
    resetTooltip();
  }, [resetTooltip]);

  const tooltipData = useSummaryChartTooltipData(hoveredDate, datasetsMap);

  const hasValuation = useMemo(() => {
    return (
      tapeDData?.some(
        trade =>
          !!trade.last_round_valuation ||
          !!trade.low_implied_valuation ||
          !!trade.mid_implied_valuation ||
          !!trade.high_implied_valuation
      ) ||
      primaryRoundsData.some(trade => !!trade.valuation) ||
      [
        ...movingAverageData,
        ...(bidOfferData.aggregated_asks ?? []),
        ...(bidOfferData.aggregated_bids ?? []),
        ...(bidOfferData.aggregated_trades ?? []),
      ].some(trade => !!trade.implied_valuation)
    );
  }, [
    bidOfferData.aggregated_asks,
    bidOfferData.aggregated_bids,
    bidOfferData.aggregated_trades,
    movingAverageData,
    primaryRoundsData,
    tapeDData,
  ]);

  return (
    <CardBase noContentPadding={true}>
      <Flex direction="column" gap="xs">
        <CompanyOverviewChartFilters
          series={series}
          onChangeSeries={setSeries}
          canChangeSeries={hasValuation}
          range={range}
          onChangeRange={range => {
            setRange(range);
          }}
          pagination={{
            page: currentPage,
            totalPages: pageCount,
            onPageChange: val => {
              setCurrentPage(val);
            },
          }}
        />
        <CompanyOverviewChartContainer>
          <canvas
            ref={canvasRef}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          />
          <SummaryChartTooltip
            date={hoveredDate}
            series={series}
            chartRef={chartRef}
            mouse={mouse}
            {...tooltipData}
          />
        </CompanyOverviewChartContainer>
        <Margin bottom="sm">
          <SummaryChartLegend
            onHover={hoveredDataSet => setHoveredDataSet(hoveredDataSet)}
            onClear={() => setHoveredDataSet(undefined)}
            showTapeD={!!tapeDData?.length}
            showClosedTrades={
              showClosedTrades && !!bidOfferData?.aggregated_trades?.length
            }
            showBids={!!bidOfferData?.aggregated_bids?.length}
            showOffers={!!bidOfferData?.aggregated_asks?.length}
            showPrimaryRounds={!!primaryRoundsData?.length}
            showValuations409A={!!valuation409AData?.length}
            showMutualFundMarks={!!movingAverageData?.length}
          />
        </Margin>
      </Flex>
    </CardBase>
  );
};
