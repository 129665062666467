import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { type FormInstance } from 'antd';

import {
  getErrorFieldsApiNames,
  getErrorFieldsNames,
} from '@npm/core/ui/components/atoms/Form/Form.utils';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { useCollapsibleFormSection } from '@npm/core/ui/components/molecules/CollapsibleFormSection';
import {
  handleApiValidationError,
  handleValidationError,
} from '@npm/core/ui/utils/form';
import { parseStringToDate } from '@npm/core/ui/utils/formatters';
import {
  type DocumentApiDocumentCreateRequest,
  type Holding,
  type HoldingApiHoldingShowRequest,
  type HoldingApiHoldingUpdateRequest,
  type IssuerEntitiesTsHoldingValidateCreateRequestContract,
  type IssuerEntityAggregate,
  CbDocumentType,
  CbHoldingState,
  CbOwnerType,
  useDocumentCreate,
  useHoldingShow,
  useHoldingUpdate,
  useIssuerEntityHoldingCreate,
  useIssuerEntityTsHoldingValidateCreate,
} from '@npm/data-access';

import { useObo } from '../../auth/user/role';
import { useCurrentRole } from '../../auth/user/role/hooks/useCurrentRole';
import { type OboDefinition } from '../../auth/user/role/userRole.types';

import {
  type HoldingCreateForm,
  type HoldingExtended,
} from './HoldingForm.types';
import {
  prepareCreateRequestParams,
  useHoldingMapper,
} from './HoldingForm.utils';

export type HoldingFormHookArgs = {
  form: FormInstance<HoldingCreateForm>;
  id?: HoldingApiHoldingShowRequest['id'];
  onUpdate?: (data: { id: number }) => void;
  onAddHoldingUploadFailed?: (id: number) => void;
  oboOverride?: OboDefinition;
  setIsSubmitDisabled: (value: boolean) => void;
  disabled?: boolean;
  onSubmit?: (holding: HoldingCreateForm, updatedHolding?: Holding) => void;
};

export const useHoldingForm = ({
  form,
  id,
  onUpdate,
  onAddHoldingUploadFailed,
  oboOverride,
  setIsSubmitDisabled,
  disabled,
  onSubmit,
}: HoldingFormHookArgs) => {
  const role = useCurrentRole();
  const { oboAccount: globalOboAccount } = useObo();
  const oboAccount = oboOverride?.account ?? globalOboAccount;

  const { setActiveKeys, ...args } = useCollapsibleFormSection({});

  const currentRegisteredName = oboAccount?.name || role?.subject?.name;

  const { showAlert, clearAlerts, withShowApiErrorMiddleware } = useAlerts();

  const queryClient = useQueryClient();

  const { data, isFetching: isLoading } = useHoldingShow(
    { id },
    {
      queryConfig: { enabled: !disabled && Boolean(id) },
    }
  );

  const { execute: create, isLoading: isCreating } =
    useIssuerEntityHoldingCreate();

  const { execute: update, isLoading: isUpdating } = useHoldingUpdate();

  const { execute: createDocument, isLoading: isUploading } =
    useDocumentCreate();

  const isHoldingVerified = data?.state.code === CbHoldingState.items.verified;

  const uploadProofOfOwnership = async (
    doc: File,
    ownerId: DocumentApiDocumentCreateRequest['ownerId']
  ) => {
    await withShowApiErrorMiddleware(createDocument)({
      file: doc,
      category: CbDocumentType.items.proof_of_ownership_document,
      ownerType: CbOwnerType.items.Holding,
      ownerId,
      displayName: doc.name,
    });
  };

  const createHolding = async (
    params: HoldingApiHoldingUpdateRequest,
    proof_of_ownership_document: File
  ) => {
    const result = await withShowApiErrorMiddleware(create)(params);

    try {
      if (proof_of_ownership_document?.name) {
        await uploadProofOfOwnership(proof_of_ownership_document, result.id);
      }

      // invalidating manually because of proof of ownership upload which needs to happen after creating the holding
      await queryClient.invalidateQueries({ queryKey: ['HoldingIndex'] });

      onUpdate?.(result);

      Notification.success({
        message: 'New holding has been successfully added.',
      });

      form.resetFields();
      form.setFieldsValue({
        registered_name: currentRegisteredName,
      });
    } catch (e) {
      // if holdings is created successfully but upload fails, stay in the drawer
      // change it to "edit" mode and show success alert inside the drawer
      onAddHoldingUploadFailed(result.id);
      showAlert('New holding has been successfully added.', {
        type: 'success',
      });

      console.error(e);
    }
  };

  const updateHolding = async (
    params: HoldingApiHoldingUpdateRequest,
    proof_of_ownership_document: File,
    values: HoldingCreateForm
  ) => {
    if (isHoldingVerified) {
      onSubmit?.(values);
      return;
    }

    if (proof_of_ownership_document?.name) {
      await uploadProofOfOwnership(proof_of_ownership_document, id);
    }

    const result = await withShowApiErrorMiddleware(update)(
      { ...params, id },
      { nullValuesHandling: 'KEEP_ALL' }
    );

    onSubmit?.(values, result);
    onUpdate?.(result);

    Notification.success({
      message: 'The holding has been successfully edited.',
    });
  };

  const submit = async () => {
    setIsSubmitDisabled(true);
    let values: HoldingCreateForm;

    clearAlerts();

    try {
      values = await form.validateFields();
    } catch (e) {
      const errorFields = getErrorFieldsNames(e);
      setActiveKeys(activeKeys => [...activeKeys, ...errorFields]);
      setIsSubmitDisabled(false);
      return handleValidationError(form, e);
    }

    const { proof_of_ownership_document, ...holdingValues } = values;
    const requestParams = prepareCreateRequestParams(holdingValues);
    const extraRequestParams = oboOverride
      ? {
          xOboAccountId: oboOverride.account?.id?.toString(),
          xOboUserId: oboOverride.representative?.user_id?.toString(),
        }
      : {};

    try {
      if (id) {
        await updateHolding(
          {
            id,
            issuerEntitiesHoldingCreateRequestContract: requestParams,
            ...extraRequestParams,
          },
          proof_of_ownership_document,
          values
        );
      } else {
        await createHolding(
          {
            id: holdingValues.issuer_entity_id, // !
            issuerEntitiesHoldingCreateRequestContract: requestParams,
            ...extraRequestParams,
          },
          proof_of_ownership_document
        );
      }
    } catch (e) {
      setIsSubmitDisabled(false);
      handleApiValidationError(form, e);
      console.error(e);
    }
  };

  // load initial form data if available
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        type: data.asset.type?.code,
        remaining_quantity: data.remaining_quantity,
        underlying_holding_type: data.underlying_holding_type?.code,
        series: data.asset.series,
        plan: data.asset.plan,
        strike_price: data.asset.strike_price,
        grant_type: data.grant_type?.code,
        acquisition_date:
          data.acquisition_date && parseStringToDate(data.acquisition_date),
        expiration_date:
          data.expiration_date && parseStringToDate(data.expiration_date),
        grant_date: data.grant_date && parseStringToDate(data.grant_date),
        registered_name: data.registered_name,
        issuer_entity_id: data.issuer_entity?.id,
        state: data.state?.code,
      });
    }
  }, [data]);

  return {
    data,
    submit,
    isLoading,
    isUpdating: isCreating || isUpdating || isUploading,
    collapsibleSectionArgs: { setActiveKeys, ...args },
  };
};

export type PostponedHoldingFormHookArgs = {
  form: FormInstance<HoldingCreateForm>;
  focusedHolding: HoldingExtended;
  setIsSubmitDisabled: (value: boolean) => void;
  onSubmit: (holding: HoldingCreateForm) => void;
  initialIssuerEntity: IssuerEntityAggregate;
};

export const usePostponedHoldingForm = ({
  form,
  focusedHolding,
  setIsSubmitDisabled,
  onSubmit,
  initialIssuerEntity,
}: PostponedHoldingFormHookArgs) => {
  const { execute: executeValidation, isLoading } =
    useIssuerEntityTsHoldingValidateCreate();

  const { mapHoldingFormValuesToHolding } = useHoldingMapper();

  const { setActiveKeys, ...args } = useCollapsibleFormSection({});

  const { clearAlerts } = useAlerts();

  const submit = async () => {
    setIsSubmitDisabled(true);
    let values: HoldingCreateForm;

    clearAlerts();

    try {
      values = await form.validateFields();
    } catch (e) {
      const errorFields = getErrorFieldsNames(e);
      setActiveKeys(activeKeys => [...activeKeys, ...errorFields]);
      setIsSubmitDisabled(false);
      return handleValidationError(form, e);
    }

    const holding = mapHoldingFormValuesToHolding(values, 'code');

    try {
      await executeValidation({
        id: initialIssuerEntity.id,
        issuerEntitiesTsHoldingValidateCreateRequestContract:
          holding as IssuerEntitiesTsHoldingValidateCreateRequestContract,
      });
    } catch (e) {
      const errorFields = getErrorFieldsApiNames(e);
      setActiveKeys(activeKeys => [...activeKeys, ...errorFields]);
      setIsSubmitDisabled(false);
      return handleApiValidationError(form, e);
    }

    onSubmit(values);
  };

  const data = focusedHolding;

  // load initial form data if available
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        type: data.asset.type?.code,
        remaining_quantity: data.remaining_quantity,
        underlying_holding_type: data.underlying_holding_type?.code,
        series: data.asset.series,
        plan: data.asset.plan,
        strike_price: data.asset.strike_price,
        grant_type: data.grant_type?.code,
        acquisition_date:
          data.acquisition_date && parseStringToDate(data.acquisition_date),
        expiration_date:
          data.expiration_date && parseStringToDate(data.expiration_date),
        grant_date: data.grant_date && parseStringToDate(data.grant_date),
        registered_name: data.registered_name,
        issuer_entity_id: data.issuer_entity?.id,
        filled_qty: data.filledQuantity,
        state: data.state?.code,
      });
    }
  }, [data]);

  return {
    data,
    submit,
    collapsibleSectionArgs: { setActiveKeys, ...args },
    isLoading,
  };
};
