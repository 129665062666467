import React from 'react';
import { type TextAreaProps as TextAreaPropsAntd } from 'antd/es/input';

import * as S from './TextArea.styles';

export type TextAreaProps = TextAreaPropsAntd & { readOnly?: boolean };

export const TextArea = (props: TextAreaProps) => {
  return (
    <S.StyledTextArea
      onFocus={e => {
        e.target.scrollIntoView({ behavior: 'smooth' });
      }}
      $readOnly={props.readOnly}
      rows={4}
      {...props}
    />
  );
};
