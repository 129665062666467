import { Flex } from '@npm/core/ui/components/atoms/common';
import { type VenusApi } from '@npm/data-access';

import { Valuations409AChart } from './Valuations409AChart';
import { Valuations409ATable } from './Valuations409ATable';

type Props = {
  data: VenusApi.FairMarketValueIndex;
  venusCompanyId: number | undefined;
  issuerEntityId: number | undefined;
};

export const Valuations409A = ({
  data,
  venusCompanyId,
  issuerEntityId,
}: Props) => {
  return (
    <Flex direction="column" gap="md">
      {!!data['409as'].length && <Valuations409AChart data={data['409as']} />}

      <Valuations409ATable
        venusCompanyId={venusCompanyId}
        issuerEntityId={issuerEntityId}
        data={data}
      />
    </Flex>
  );
};
