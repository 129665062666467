import { useCallback } from 'react';
import Clipboard from 'clipboard';

import { Notification } from '@npm/core/ui/components/atoms/Notification';

const UTM_SOURCE = 'bkr';

export const useCopyOrderItemLink = (orderItemId: number) => {
  return useCallback(() => {
    const currentHost = window.location.origin;
    const textToCopy = `${currentHost}/second-market/sponsored-investor/opportunity/${orderItemId}?allAccounts=1&companySelect=companyActive%3Dall_active&utm_source=${UTM_SOURCE}`;

    const tempButton = document.createElement('button');
    document.body.appendChild(tempButton);

    const clipboard = new Clipboard(tempButton, {
      text: () => textToCopy,
    });

    clipboard.on('success', () => {
      Notification.success({
        message:
          'Link copied. Please note, this link works for users of the Investor Workstation.',
      });
    });

    clipboard.on('error', () => {
      Notification.error({ message: 'Operation failed. Please try again.' });
    });

    tempButton.click();

    clipboard.destroy();
    document.body.removeChild(tempButton);
  }, [orderItemId]);
};
