import styled, { type DefaultTheme } from 'styled-components';

import { centerFlex, useBreakpoint } from '@npm/core/ui/utils/style';

export const IconWrapper = styled.div<{
  $background: keyof DefaultTheme['color']['general']['layout'];
}>`
  ${centerFlex}
  transform: rotate(45deg);
  width: 48px;
  height: 48px;
  border-radius: 50%;

  svg {
    color: ${({ theme }) => theme.color.info.typography.primary};
  }

  ${({ theme, $background }) =>
    `background-color: ${theme.color.general.layout[$background]};`}

  ${({ theme }) => useBreakpoint(theme).mobile`
    width: 32px;
    height: 32px;
  `}
`;
