type PathMetadata = typeof advisorGeneralMetadata;

export const registerAdvisorGeneralMetadata = (metadata: PathMetadata) => {
  advisorGeneralMetadata.forEach(m => {
    metadata.push(m);
  });
};

export const advisorGeneralMetadata = [
  {
    pathRegex: /.*advisor-workstation\/dashboard.*/,
    metadata: {
      screenId: 'NFE-6723',
      screenName: 'Advisor Dashboard',
    },
  },
  {
    pathRegex: /.*advisor-workstation\/prospects\/\d\.*/,
    metadata: {
      screenId: 'NFE-6544',
      screenName: 'Prospects - Detail',
    },
  },
  {
    pathRegex: /.*advisor-workstation\/prospects.*/,
    metadata: {
      screenId: 'NFE-6544',
      screenName: 'Prospects - List',
    },
  },
];
