import type { CbLabelComponent } from '@npm/utils';

import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  accounts: 'accounts',
  agreements: 'agreements',
  marketplace: 'marketplace',
} as const;

export const CbNotificationCategory = {
  code: Codebooks.NOTIFICATION_CATEGORY,
  items: Items,
  getLabel: (Component: CbLabelComponent, codebookItem: CodebookItem) =>
    Component({
      variant: 'info',
      label: codebookItem?.name,
    }),
};
