import React, { useMemo } from 'react';
import { type ExpandableConfig } from 'antd/lib/table/interface';
import { useTheme } from 'styled-components';

import { CardBase } from '@npm/core/ui/components/atoms/CardBase';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import {
  type ExtendedColumnType,
  Table,
} from '@npm/core/ui/components/molecules/Table';

import { sortArrayByDateField } from '../../../CompanyOverview.utils';
import {
  TableScrollIndicator,
  useScrollIndicatorClick,
} from '../../../components/TableScrollIndicator';

import * as S from './Levels.styles';

const TABLE_MAX_HEIGHT = 240;

type Props<T> = {
  title: string;
  tooltip?: React.ReactNode;
  noDataTitle: string;
  items: T[];
  columns: ExtendedColumnType<T>[];
  icon: React.ReactElement;
  expandable?: ExpandableConfig<T>;
};

export const Levels = <T,>({
  title,
  tooltip,
  noDataTitle,
  items,
  columns,
  icon,
  expandable,
}: Props<T>) => {
  const theme = useTheme();
  const data = useMemo(() => sortArrayByDateField(items, 'desc'), [items]);
  const showScrollIndicator = data?.length > 4;
  const tableId = title.replace(' ', '-').toLowerCase();

  const { tableProps, scrollIndicatorProps } = useScrollIndicatorClick(
    tableId,
    TABLE_MAX_HEIGHT,
    showScrollIndicator
  );

  const header = (
    <Margin left={'md'} top={'sm'} bottom={'sm'}>
      <Flex direction="row" gap={'xs'} align={'center'}>
        {icon && (
          <S.HeadingTooltipIconWrapper>{icon}</S.HeadingTooltipIconWrapper>
        )}
        <Heading variant="h3">
          {title}{' '}
          <Text colorVariant="tertiary" as="span">
            ({data.length})
          </Text>
        </Heading>

        {tooltip && (
          <Tooltip title={tooltip}>
            <S.HeadingTooltipIconWrapper>
              <Icon
                name="info-circle"
                size="sm"
                color={theme.color.info.typography.primary}
              />
            </S.HeadingTooltipIconWrapper>
          </Tooltip>
        )}
      </Flex>
    </Margin>
  );

  return (
    <CardBase
      noContentPadding={true}
      header={header}
      style={{ position: 'relative' }}
    >
      <Table
        id={tableId}
        disablePagination={true}
        outlined={false}
        dataSource={data}
        columns={columns}
        noDataArea={<NoDataArea title={noDataTitle} />}
        expandable={expandable}
        {...tableProps}
      />
      {showScrollIndicator && (
        <TableScrollIndicator {...scrollIndicatorProps} />
      )}
    </CardBase>
  );
};
