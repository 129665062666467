import { type IconNames } from '@npm/utils';

import { Codebooks } from './codebooks.types';

const items = {
  newest: 'newest',
  oldest: 'oldest',
  notional_descending: 'notional_descending',
  notional_ascending: 'notional_ascending',
  valuation_descending: 'valuation_descending',
  valuation_ascending: 'valuation_ascending',
  price_descending: 'price_descending',
  price_ascending: 'price_ascending',
  expiry_descending: 'expiry_descending',
  expiry_ascending: 'expiry_ascending',
} as const;

const iconMap: Partial<Record<keyof typeof items, IconNames>> = {
  notional_descending: 'sort-descending',
  notional_ascending: 'sort-ascending',
  valuation_descending: 'sort-descending',
  valuation_ascending: 'sort-ascending',
};

export const CbSecondMarketOrderSort = {
  code: Codebooks.SECONDMARKET_ORDER_SORT,
  items,
  iconMap,
};
