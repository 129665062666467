import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Chart } from 'chart.js';

import { CardBase } from '@npm/core/ui/components/atoms/CardBase';
import {
  CrosshairPlugin,
  useChartOptions,
} from '@npm/core/ui/components/atoms/Charts';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';

import { useCompanyOverviewChartFilter } from '../../../CompanyOverview.hooks';
import type { CompanyOverviewChartSeries } from '../../../CompanyOverview.types';
import {
  getCompanyOverviewXScaleConfig,
  getCompanyOverviewYScaleConfig,
  sortArrayByDateField,
} from '../../../CompanyOverview.utils';
import { CompanyOverviewChartContainer } from '../../../components/CompanyOverviewChartContainer';
import { CompanyOverviewChartFilters } from '../../../components/CompanyOverviewChartFilters/CompanyOverviewChartFilters';
import { type TapeDValue } from '../../TapeD.types';

import { useTapeDDataSets, useTapeDDateLimits } from './TapeDChart.hooks';
import { type TapeDChartPoint } from './TapeDChart.types';
import {
  getLatestWeekday,
  getTapeDChartTooltip,
  showTapeDChartTooltip,
  TAPE_D_CHART_HTML_ID,
} from './TapeDChart.utils';
import { TapeDChartTooltip } from './TapeDChartTooltip';

type Props = {
  data: TapeDValue[];
};

export const TapeDChart = ({ data }: Props) => {
  const [activeDataPoint, setActiveDataPoint] = useState<
    TapeDValue | undefined
  >();

  // Dark-mode context used to fix issues with switching between dark and light mode
  const { isDarkMode } = useDarkModeContext();

  const sortedData = useMemo(() => {
    return sortArrayByDateField(data);
  }, [data]);

  const [series, setSeries] = useState<CompanyOverviewChartSeries>('PPS');

  const [minDate, maxDate] = useTapeDDateLimits(sortedData);

  const {
    setCurrentPage,
    currentMinDate,
    currentMaxDate,
    currentPage,
    pageCount,
    range,
    setRange,
  } = useCompanyOverviewChartFilter(minDate, maxDate);

  const labels = useMemo(() => {
    return sortedData
      .filter(({ date }) => {
        return (
          new Date(date) >= currentMinDate && new Date(date) < currentMaxDate
        );
      })
      .map(({ date }) => date);
  }, [sortedData, currentMinDate, currentMaxDate]);

  const datasets = useTapeDDataSets(sortedData, series);

  const options = useChartOptions();

  const canvasRef = useRef<HTMLCanvasElement>();

  const chartRef = useRef<Chart<'line', TapeDChartPoint[], string>>();

  const destroyChart = () => {
    if (chartRef.current) {
      chartRef.current.destroy();
      chartRef.current = null;
    }
  };

  const resetTooltip = () => {
    if (!chartRef.current) return;

    getTapeDChartTooltip(chartRef.current?.canvas).style.opacity = '0';
    chartRef.current.update('none');
  };

  const handleMouseMove: React.MouseEventHandler<HTMLCanvasElement> = event => {
    const TOLERANCE = 25;

    const rect = chartRef.current.canvas.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;

    let minDistance = Infinity;
    let nearestPoint = null;

    chartRef.current.data.datasets.forEach((dataset, datasetIndex) => {
      if (dataset.label !== 'Mid Estimate') return;
      // the latest weekday in the data, fallback to Thu
      const latestWeekday = getLatestWeekday(data) ?? 4;

      const meta = chartRef.current.getDatasetMeta(datasetIndex);

      const points = meta.data
        .map((point, index) => {
          const pointData = dataset.data[index].raw.data;
          // show tooltip only for 1 day of the week (which day is determined by the latestWeekday)
          return new Date(pointData.date).getDay() === latestWeekday
            ? {
                ...point,
                pointData,
              }
            : null;
        })
        .filter(Boolean);

      for (let i = 0; i < points.length; i++) {
        const distance = Math.abs(points[i].x - mouseX);

        if (distance < minDistance && distance < TOLERANCE) {
          minDistance = distance;
          nearestPoint = points[i];
        }
      }

      if (nearestPoint) {
        const dataPoint = nearestPoint.pointData;

        setActiveDataPoint(dataPoint);
        showTapeDChartTooltip({ canvas: canvasRef.current }, nearestPoint);
      } else {
        setActiveDataPoint(undefined);
        resetTooltip();
      }
    });
  };

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');

    chartRef.current = new Chart<'line', TapeDChartPoint[], string>(ctx, {
      type: 'line',
      data: {
        labels,
        datasets,
      },
      options: options({
        scales: {
          x: getCompanyOverviewXScaleConfig(currentMinDate, currentMaxDate),
          y: getCompanyOverviewYScaleConfig(),
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      }),
      plugins: [CrosshairPlugin],
    });

    chartRef.current.update('none');

    return () => destroyChart();
  }, [labels, datasets, isDarkMode, options, currentMinDate, currentMaxDate]);

  const hasValuation = useMemo(() => {
    return data?.some(
      trade =>
        !!trade.last_round_valuation ||
        !!trade.low_implied_valuation ||
        !!trade.mid_implied_valuation ||
        !!trade.high_implied_valuation
    );
  }, [data]);

  return (
    <CardBase noContentPadding={true}>
      <Flex direction="column" gap="xs">
        <CompanyOverviewChartFilters
          series={series}
          onChangeSeries={setSeries}
          canChangeSeries={hasValuation}
          range={range}
          onChangeRange={range => {
            setRange(range);
            resetTooltip();
          }}
          pagination={{
            page: currentPage,
            totalPages: pageCount,
            onPageChange: val => {
              setCurrentPage(val);
              resetTooltip();
            },
          }}
        />
        <CompanyOverviewChartContainer>
          <canvas
            ref={canvasRef}
            onMouseMove={handleMouseMove}
            onMouseLeave={() => {
              setActiveDataPoint(undefined);
              resetTooltip();
            }}
          ></canvas>
          <div
            id={TAPE_D_CHART_HTML_ID}
            className="html-chart-tooltip"
            style={{ opacity: activeDataPoint ? 0.9 : 0 }}
          >
            <TapeDChartTooltip value={activeDataPoint} series={series} />
          </div>
        </CompanyOverviewChartContainer>
      </Flex>
    </CardBase>
  );
};
