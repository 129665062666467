import { Codebooks } from './codebooks.types';

const Items = {
  issuer_entity: 'issuer_entity',
  match: 'match',
  advisor_referral: 'advisor_referral',
} as const;

export type NoteSubjectTypeCode = keyof typeof Items;

export const CbNoteSubjectType = {
  code: Codebooks.NOTE_SUBJECT_TYPE,
  items: Items,
};
