import React, { type ComponentProps } from 'react';
import { useTheme } from 'styled-components';

import { useDarkModeContext } from '../../../context/DarkModeContext';
import { formatQuantity } from '../../../utils/formatters';
import { Flex } from '../../atoms/common';
import { Icon } from '../../atoms/Icon';
import { Text } from '../../atoms/Typography';

type Props = {
  change: number;
  style?: React.CSSProperties;
  iconSize?: ComponentProps<typeof Icon>['size'];
  textSize?: ComponentProps<typeof Text>['size'];
  maximumFractionDigits?: number;
  isMuted?: boolean;
};

export const PercentageChange = ({
  change,
  style,
  iconSize,
  textSize,
  maximumFractionDigits = 2,
  isMuted = false,
}: Props) => {
  const theme = useTheme();
  const { isDarkMode } = useDarkModeContext();

  const color = isMuted
    ? // TODO: cleanup after sync with designers (use color from theme)
      theme.color[`${change < 0 ? 'red' : 'green'}_${isDarkMode ? '20' : '80'}`]
    : theme.color[change < 0 ? 'error' : 'success'].typography.primary;

  if (!change) {
    return <Text size={textSize || 'sm'}>--</Text>;
  }

  return (
    <Flex align="center" style={style}>
      <Icon
        name={change < 0 ? 'arrow-narrow-45-down' : 'arrow-narrow-45-up'}
        size={iconSize || 'xs'}
        color={color}
      />
      <Text size={textSize || 'sm'} style={{ color }}>
        {formatQuantity(Math.abs(change), { maximumFractionDigits })}%
      </Text>
    </Flex>
  );
};
