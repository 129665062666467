import type { IssuerEntityAggregate, VenusApi } from '@npm/data-access';

import { type CompanyDataSectionKey } from '../../CompanyOverview.types';
import { NotCoveredBanner } from '../NotCoveredBanner';
import { type SectionHeadingProps, SectionHeading } from '../SectionHeading';

type Props = {
  sectionKey: CompanyDataSectionKey;
  children: React.ReactNode;
  issuerEntity: IssuerEntityAggregate | undefined;
  companyProfile: VenusApi.CompanyProfile | undefined;
  hasData?: boolean;
  isCoveredSection?: boolean;
} & SectionHeadingProps;

export const CompanyDataSection = ({
  sectionKey,
  children,
  issuerEntity,
  companyProfile,
  hasData,
  isCoveredSection = false,
  title,
  titleSuffix,
  tooltip,
}: Props) => {
  if (!isCoveredSection && !hasData) {
    return null;
  }

  if (
    isCoveredSection &&
    (!companyProfile || (!hasData && companyProfile.covered))
  ) {
    return null;
  }

  return (
    <div id={sectionKey}>
      <SectionHeading
        title={title}
        titleSuffix={titleSuffix}
        tooltip={tooltip}
      />
      {isCoveredSection ? (
        hasData ? (
          children
        ) : (
          <NotCoveredBanner issuerEntity={issuerEntity} />
        )
      ) : (
        children
      )}
    </div>
  );
};
