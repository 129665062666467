import { RBAC } from '@npm/core/ui/models/permissions';
import { type advisorWorkstationRouteKeys } from '@npm/core/ui/routes';
import { componentLoader } from '@npm/core/ui/routes/componentLoader';
import { type RouteDefinition } from '@npm/core/ui/routes/router.types';

const { isAdvisorAdmin } = RBAC.advisor;

type RouteDef = RouteDefinition & {
  path: advisorWorkstationRouteKeys;
  subRoutes?: RouteDef[];
};

const workstationPrefix = 'advisor-workstation';

export const registerRoutes = (desc: RouteDefinition[]) => {
  routes.forEach(r => {
    desc.push(r);
  });
};

export const routes: RouteDef[] = [
  {
    path: `/${workstationPrefix}/dashboard`,
    component: componentLoader(
      () => import(`libs/workstations/advisor/src/pages/dashboard`)
    ),
    pageHistory: {
      isRoot: true,
    },
    title: 'Dashboard',
    permissionsChecks: [isAdvisorAdmin],
  },
  {
    path: `/${workstationPrefix}/prospects`,
    component: componentLoader(
      () => import(`libs/workstations/advisor/src/pages/prospects`)
    ),
    pageHistory: {
      isRoot: true,
    },
    title: 'Prospects',
    permissionsChecks: [isAdvisorAdmin],
    subRoutes: [
      {
        path: `/${workstationPrefix}/prospects/:prospectId`,
        component: componentLoader(
          () => import(`libs/workstations/advisor/src/pages/prospects/detail`)
        ),
        title: 'Prospect Detail',
        permissionsChecks: [isAdvisorAdmin],
      },
    ],
  },
];
