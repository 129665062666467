/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DestroySuccess } from '../model';
// @ts-ignore
import { Holding } from '../model';
// @ts-ignore
import { HoldingIndex } from '../model';
// @ts-ignore
import { HoldingsStatusRequestContract } from '../model';
// @ts-ignore
import { IssuerEntitiesHoldingCreateRequestContract } from '../model';
// @ts-ignore
import { IssuerEntitiesTsHoldingValidateCreateRequestContract } from '../model';
// @ts-ignore
import { OperationSuccess } from '../model';
/**
 * HoldingApi - axios parameter creator
 * @export
 */
export const HoldingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Destroy the holding specified in the path
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdingDestroy: async (id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('holdingDestroy', 'id', id)
            const localVarPath = changeUrl(`/api/holdings/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Holdings list
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {number} [accountId] 
         * @param {Array<number>} [assignedUserId] 
         * @param {number} [issuerEntityId] 
         * @param {number} [issuanceId] 
         * @param {number} [eventId] 
         * @param {boolean} [secondmarket] 
         * @param {Array<string>} [holdingState] 
         * @param {Array<string>} [assetType] 
         * @param {boolean} [includeSpvs] 
         * @param {boolean} [includePortfolioHoldings] 
         * @param {boolean} [preVerified] 
         * @param {Array<string>} [accountType] 
         * @param {string} [updatedAfter] 
         * @param {string} [sort] 
         * @param {boolean} [proofOfOwnership] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdingIndex: async (page?: number, size?: number, search?: string, accountId?: number, assignedUserId?: Array<number>, issuerEntityId?: number, issuanceId?: number, eventId?: number, secondmarket?: boolean, holdingState?: Array<string>, assetType?: Array<string>, includeSpvs?: boolean, includePortfolioHoldings?: boolean, preVerified?: boolean, accountType?: Array<string>, updatedAfter?: string, sort?: string, proofOfOwnership?: boolean, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/holdings/`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['account_id'] = accountId;
            }

            if (assignedUserId) {
                localVarQueryParameter['assigned_user_id'] = assignedUserId.join(COLLECTION_FORMATS.csv);
            }

            if (issuerEntityId !== undefined) {
                localVarQueryParameter['issuer_entity_id'] = issuerEntityId;
            }

            if (issuanceId !== undefined) {
                localVarQueryParameter['issuance_id'] = issuanceId;
            }

            if (eventId !== undefined) {
                localVarQueryParameter['event_id'] = eventId;
            }

            if (secondmarket !== undefined) {
                localVarQueryParameter['secondmarket'] = secondmarket;
            }

            if (holdingState) {
                localVarQueryParameter['holding_state'] = holdingState.join(COLLECTION_FORMATS.csv);
            }

            if (assetType) {
                localVarQueryParameter['asset_type'] = assetType.join(COLLECTION_FORMATS.csv);
            }

            if (includeSpvs !== undefined) {
                localVarQueryParameter['include_spvs'] = includeSpvs;
            }

            if (includePortfolioHoldings !== undefined) {
                localVarQueryParameter['include_portfolio_holdings'] = includePortfolioHoldings;
            }

            if (preVerified !== undefined) {
                localVarQueryParameter['pre_verified'] = preVerified;
            }

            if (accountType) {
                localVarQueryParameter['account_type'] = accountType.join(COLLECTION_FORMATS.csv);
            }

            if (updatedAfter !== undefined) {
                localVarQueryParameter['updated_after'] = updatedAfter;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (proofOfOwnership !== undefined) {
                localVarQueryParameter['proof_of_ownership'] = proofOfOwnership;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Show the holding specified in the path
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdingShow: async (id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('holdingShow', 'id', id)
            const localVarPath = changeUrl(`/api/holdings/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the holding status specified in the path
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {HoldingsStatusRequestContract} [holdingsStatusRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdingStatusUpdate: async (id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, holdingsStatusRequestContract?: HoldingsStatusRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('holdingStatusUpdate', 'id', id)
            const localVarPath = changeUrl(`/api/holdings/{id}/status`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(holdingsStatusRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the holding specified in the path
         * @param {number} id 
         * @param {IssuerEntitiesHoldingCreateRequestContract} issuerEntitiesHoldingCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdingUpdate: async (id: number, issuerEntitiesHoldingCreateRequestContract: IssuerEntitiesHoldingCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('holdingUpdate', 'id', id)
            // verify required parameter 'issuerEntitiesHoldingCreateRequestContract' is not null or undefined
            assertParamExists('holdingUpdate', 'issuerEntitiesHoldingCreateRequestContract', issuerEntitiesHoldingCreateRequestContract)
            const localVarPath = changeUrl(`/api/holdings/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issuerEntitiesHoldingCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creates a holding for the issuer entity
         * @param {number} id 
         * @param {IssuerEntitiesHoldingCreateRequestContract} issuerEntitiesHoldingCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerEntityHoldingCreate: async (id: number, issuerEntitiesHoldingCreateRequestContract: IssuerEntitiesHoldingCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('issuerEntityHoldingCreate', 'id', id)
            // verify required parameter 'issuerEntitiesHoldingCreateRequestContract' is not null or undefined
            assertParamExists('issuerEntityHoldingCreate', 'issuerEntitiesHoldingCreateRequestContract', issuerEntitiesHoldingCreateRequestContract)
            const localVarPath = changeUrl(`/api/issuer-entities/{id}/holdings`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issuerEntitiesHoldingCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creates a holding for the issuer entity
         * @param {number} id 
         * @param {IssuerEntitiesTsHoldingValidateCreateRequestContract} issuerEntitiesTsHoldingValidateCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerEntityTsHoldingValidateCreate: async (id: number, issuerEntitiesTsHoldingValidateCreateRequestContract: IssuerEntitiesTsHoldingValidateCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('issuerEntityTsHoldingValidateCreate', 'id', id)
            // verify required parameter 'issuerEntitiesTsHoldingValidateCreateRequestContract' is not null or undefined
            assertParamExists('issuerEntityTsHoldingValidateCreate', 'issuerEntitiesTsHoldingValidateCreateRequestContract', issuerEntitiesTsHoldingValidateCreateRequestContract)
            const localVarPath = changeUrl(`/api/issuer-entities/{id}/ts-holding-validate`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issuerEntitiesTsHoldingValidateCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HoldingApi - functional programming interface
 * @export
 */
export const HoldingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HoldingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Destroy the holding specified in the path
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holdingDestroy(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DestroySuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holdingDestroy(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Holdings list
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {number} [accountId] 
         * @param {Array<number>} [assignedUserId] 
         * @param {number} [issuerEntityId] 
         * @param {number} [issuanceId] 
         * @param {number} [eventId] 
         * @param {boolean} [secondmarket] 
         * @param {Array<string>} [holdingState] 
         * @param {Array<string>} [assetType] 
         * @param {boolean} [includeSpvs] 
         * @param {boolean} [includePortfolioHoldings] 
         * @param {boolean} [preVerified] 
         * @param {Array<string>} [accountType] 
         * @param {string} [updatedAfter] 
         * @param {string} [sort] 
         * @param {boolean} [proofOfOwnership] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holdingIndex(page?: number, size?: number, search?: string, accountId?: number, assignedUserId?: Array<number>, issuerEntityId?: number, issuanceId?: number, eventId?: number, secondmarket?: boolean, holdingState?: Array<string>, assetType?: Array<string>, includeSpvs?: boolean, includePortfolioHoldings?: boolean, preVerified?: boolean, accountType?: Array<string>, updatedAfter?: string, sort?: string, proofOfOwnership?: boolean, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoldingIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holdingIndex(page, size, search, accountId, assignedUserId, issuerEntityId, issuanceId, eventId, secondmarket, holdingState, assetType, includeSpvs, includePortfolioHoldings, preVerified, accountType, updatedAfter, sort, proofOfOwnership, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Show the holding specified in the path
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holdingShow(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Holding>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holdingShow(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update the holding status specified in the path
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {HoldingsStatusRequestContract} [holdingsStatusRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holdingStatusUpdate(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, holdingsStatusRequestContract?: HoldingsStatusRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Holding>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holdingStatusUpdate(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, holdingsStatusRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update the holding specified in the path
         * @param {number} id 
         * @param {IssuerEntitiesHoldingCreateRequestContract} issuerEntitiesHoldingCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holdingUpdate(id: number, issuerEntitiesHoldingCreateRequestContract: IssuerEntitiesHoldingCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Holding>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holdingUpdate(id, issuerEntitiesHoldingCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary creates a holding for the issuer entity
         * @param {number} id 
         * @param {IssuerEntitiesHoldingCreateRequestContract} issuerEntitiesHoldingCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuerEntityHoldingCreate(id: number, issuerEntitiesHoldingCreateRequestContract: IssuerEntitiesHoldingCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Holding>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuerEntityHoldingCreate(id, issuerEntitiesHoldingCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary creates a holding for the issuer entity
         * @param {number} id 
         * @param {IssuerEntitiesTsHoldingValidateCreateRequestContract} issuerEntitiesTsHoldingValidateCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuerEntityTsHoldingValidateCreate(id: number, issuerEntitiesTsHoldingValidateCreateRequestContract: IssuerEntitiesTsHoldingValidateCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationSuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuerEntityTsHoldingValidateCreate(id, issuerEntitiesTsHoldingValidateCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HoldingApi - factory interface
 * @export
 */
export const HoldingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HoldingApiFp(configuration)
    return {
        /**
         * 
         * @summary Destroy the holding specified in the path
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdingDestroy(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<DestroySuccess> {
            return localVarFp.holdingDestroy(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Holdings list
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {number} [accountId] 
         * @param {Array<number>} [assignedUserId] 
         * @param {number} [issuerEntityId] 
         * @param {number} [issuanceId] 
         * @param {number} [eventId] 
         * @param {boolean} [secondmarket] 
         * @param {Array<string>} [holdingState] 
         * @param {Array<string>} [assetType] 
         * @param {boolean} [includeSpvs] 
         * @param {boolean} [includePortfolioHoldings] 
         * @param {boolean} [preVerified] 
         * @param {Array<string>} [accountType] 
         * @param {string} [updatedAfter] 
         * @param {string} [sort] 
         * @param {boolean} [proofOfOwnership] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdingIndex(page?: number, size?: number, search?: string, accountId?: number, assignedUserId?: Array<number>, issuerEntityId?: number, issuanceId?: number, eventId?: number, secondmarket?: boolean, holdingState?: Array<string>, assetType?: Array<string>, includeSpvs?: boolean, includePortfolioHoldings?: boolean, preVerified?: boolean, accountType?: Array<string>, updatedAfter?: string, sort?: string, proofOfOwnership?: boolean, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<HoldingIndex> {
            return localVarFp.holdingIndex(page, size, search, accountId, assignedUserId, issuerEntityId, issuanceId, eventId, secondmarket, holdingState, assetType, includeSpvs, includePortfolioHoldings, preVerified, accountType, updatedAfter, sort, proofOfOwnership, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Show the holding specified in the path
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdingShow(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<Holding> {
            return localVarFp.holdingShow(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the holding status specified in the path
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {HoldingsStatusRequestContract} [holdingsStatusRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdingStatusUpdate(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, holdingsStatusRequestContract?: HoldingsStatusRequestContract, options?: any): AxiosPromise<Holding> {
            return localVarFp.holdingStatusUpdate(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, holdingsStatusRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the holding specified in the path
         * @param {number} id 
         * @param {IssuerEntitiesHoldingCreateRequestContract} issuerEntitiesHoldingCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdingUpdate(id: number, issuerEntitiesHoldingCreateRequestContract: IssuerEntitiesHoldingCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<Holding> {
            return localVarFp.holdingUpdate(id, issuerEntitiesHoldingCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary creates a holding for the issuer entity
         * @param {number} id 
         * @param {IssuerEntitiesHoldingCreateRequestContract} issuerEntitiesHoldingCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerEntityHoldingCreate(id: number, issuerEntitiesHoldingCreateRequestContract: IssuerEntitiesHoldingCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<Holding> {
            return localVarFp.issuerEntityHoldingCreate(id, issuerEntitiesHoldingCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary creates a holding for the issuer entity
         * @param {number} id 
         * @param {IssuerEntitiesTsHoldingValidateCreateRequestContract} issuerEntitiesTsHoldingValidateCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerEntityTsHoldingValidateCreate(id: number, issuerEntitiesTsHoldingValidateCreateRequestContract: IssuerEntitiesTsHoldingValidateCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<OperationSuccess> {
            return localVarFp.issuerEntityTsHoldingValidateCreate(id, issuerEntitiesTsHoldingValidateCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for holdingDestroy operation in HoldingApi.
 * @export
 * @interface HoldingApiHoldingDestroyRequest
 */
export interface HoldingApiHoldingDestroyRequest {
    /**
     * 
     * @type {number}
     * @memberof HoldingApiHoldingDestroy
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingDestroy
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingDestroy
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingDestroy
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingDestroy
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingDestroy
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingDestroy
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for holdingIndex operation in HoldingApi.
 * @export
 * @interface HoldingApiHoldingIndexRequest
 */
export interface HoldingApiHoldingIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof HoldingApiHoldingIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof HoldingApiHoldingIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingIndex
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof HoldingApiHoldingIndex
     */
    readonly accountId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof HoldingApiHoldingIndex
     */
    readonly assignedUserId?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof HoldingApiHoldingIndex
     */
    readonly issuerEntityId?: number

    /**
     * 
     * @type {number}
     * @memberof HoldingApiHoldingIndex
     */
    readonly issuanceId?: number

    /**
     * 
     * @type {number}
     * @memberof HoldingApiHoldingIndex
     */
    readonly eventId?: number

    /**
     * 
     * @type {boolean}
     * @memberof HoldingApiHoldingIndex
     */
    readonly secondmarket?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof HoldingApiHoldingIndex
     */
    readonly holdingState?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof HoldingApiHoldingIndex
     */
    readonly assetType?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof HoldingApiHoldingIndex
     */
    readonly includeSpvs?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof HoldingApiHoldingIndex
     */
    readonly includePortfolioHoldings?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof HoldingApiHoldingIndex
     */
    readonly preVerified?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof HoldingApiHoldingIndex
     */
    readonly accountType?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingIndex
     */
    readonly updatedAfter?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingIndex
     */
    readonly sort?: string

    /**
     * 
     * @type {boolean}
     * @memberof HoldingApiHoldingIndex
     */
    readonly proofOfOwnership?: boolean

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for holdingShow operation in HoldingApi.
 * @export
 * @interface HoldingApiHoldingShowRequest
 */
export interface HoldingApiHoldingShowRequest {
    /**
     * 
     * @type {number}
     * @memberof HoldingApiHoldingShow
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for holdingStatusUpdate operation in HoldingApi.
 * @export
 * @interface HoldingApiHoldingStatusUpdateRequest
 */
export interface HoldingApiHoldingStatusUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof HoldingApiHoldingStatusUpdate
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingStatusUpdate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingStatusUpdate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingStatusUpdate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingStatusUpdate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingStatusUpdate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingStatusUpdate
     */
    readonly xUserRoleId?: string

    /**
     * 
     * @type {HoldingsStatusRequestContract}
     * @memberof HoldingApiHoldingStatusUpdate
     */
    readonly holdingsStatusRequestContract?: HoldingsStatusRequestContract
}

/**
 * Request parameters for holdingUpdate operation in HoldingApi.
 * @export
 * @interface HoldingApiHoldingUpdateRequest
 */
export interface HoldingApiHoldingUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof HoldingApiHoldingUpdate
     */
    readonly id: number

    /**
     * 
     * @type {IssuerEntitiesHoldingCreateRequestContract}
     * @memberof HoldingApiHoldingUpdate
     */
    readonly issuerEntitiesHoldingCreateRequestContract: IssuerEntitiesHoldingCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingUpdate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingUpdate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingUpdate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingUpdate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingUpdate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiHoldingUpdate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for issuerEntityHoldingCreate operation in HoldingApi.
 * @export
 * @interface HoldingApiIssuerEntityHoldingCreateRequest
 */
export interface HoldingApiIssuerEntityHoldingCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof HoldingApiIssuerEntityHoldingCreate
     */
    readonly id: number

    /**
     * 
     * @type {IssuerEntitiesHoldingCreateRequestContract}
     * @memberof HoldingApiIssuerEntityHoldingCreate
     */
    readonly issuerEntitiesHoldingCreateRequestContract: IssuerEntitiesHoldingCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof HoldingApiIssuerEntityHoldingCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiIssuerEntityHoldingCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiIssuerEntityHoldingCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiIssuerEntityHoldingCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiIssuerEntityHoldingCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiIssuerEntityHoldingCreate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for issuerEntityTsHoldingValidateCreate operation in HoldingApi.
 * @export
 * @interface HoldingApiIssuerEntityTsHoldingValidateCreateRequest
 */
export interface HoldingApiIssuerEntityTsHoldingValidateCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof HoldingApiIssuerEntityTsHoldingValidateCreate
     */
    readonly id: number

    /**
     * 
     * @type {IssuerEntitiesTsHoldingValidateCreateRequestContract}
     * @memberof HoldingApiIssuerEntityTsHoldingValidateCreate
     */
    readonly issuerEntitiesTsHoldingValidateCreateRequestContract: IssuerEntitiesTsHoldingValidateCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof HoldingApiIssuerEntityTsHoldingValidateCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiIssuerEntityTsHoldingValidateCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiIssuerEntityTsHoldingValidateCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiIssuerEntityTsHoldingValidateCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiIssuerEntityTsHoldingValidateCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof HoldingApiIssuerEntityTsHoldingValidateCreate
     */
    readonly xUserRoleId?: string
}

/**
 * HoldingApi - object-oriented interface
 * @export
 * @class HoldingApi
 * @extends {BaseAPI}
 */
export class HoldingApi extends BaseAPI {
    /**
     * 
     * @summary Destroy the holding specified in the path
     * @param {HoldingApiHoldingDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoldingApi
     */
    public holdingDestroy(requestParameters: HoldingApiHoldingDestroyRequest, options?: AxiosRequestConfig) {
        return HoldingApiFp(this.configuration).holdingDestroy(requestParameters.id, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Holdings list
     * @param {HoldingApiHoldingIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoldingApi
     */
    public holdingIndex(requestParameters: HoldingApiHoldingIndexRequest = {}, options?: AxiosRequestConfig) {
        return HoldingApiFp(this.configuration).holdingIndex(requestParameters.page, requestParameters.size, requestParameters.search, requestParameters.accountId, requestParameters.assignedUserId, requestParameters.issuerEntityId, requestParameters.issuanceId, requestParameters.eventId, requestParameters.secondmarket, requestParameters.holdingState, requestParameters.assetType, requestParameters.includeSpvs, requestParameters.includePortfolioHoldings, requestParameters.preVerified, requestParameters.accountType, requestParameters.updatedAfter, requestParameters.sort, requestParameters.proofOfOwnership, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Show the holding specified in the path
     * @param {HoldingApiHoldingShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoldingApi
     */
    public holdingShow(requestParameters: HoldingApiHoldingShowRequest, options?: AxiosRequestConfig) {
        return HoldingApiFp(this.configuration).holdingShow(requestParameters.id, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the holding status specified in the path
     * @param {HoldingApiHoldingStatusUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoldingApi
     */
    public holdingStatusUpdate(requestParameters: HoldingApiHoldingStatusUpdateRequest, options?: AxiosRequestConfig) {
        return HoldingApiFp(this.configuration).holdingStatusUpdate(requestParameters.id, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, requestParameters.holdingsStatusRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the holding specified in the path
     * @param {HoldingApiHoldingUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoldingApi
     */
    public holdingUpdate(requestParameters: HoldingApiHoldingUpdateRequest, options?: AxiosRequestConfig) {
        return HoldingApiFp(this.configuration).holdingUpdate(requestParameters.id, requestParameters.issuerEntitiesHoldingCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary creates a holding for the issuer entity
     * @param {HoldingApiIssuerEntityHoldingCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoldingApi
     */
    public issuerEntityHoldingCreate(requestParameters: HoldingApiIssuerEntityHoldingCreateRequest, options?: AxiosRequestConfig) {
        return HoldingApiFp(this.configuration).issuerEntityHoldingCreate(requestParameters.id, requestParameters.issuerEntitiesHoldingCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary creates a holding for the issuer entity
     * @param {HoldingApiIssuerEntityTsHoldingValidateCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoldingApi
     */
    public issuerEntityTsHoldingValidateCreate(requestParameters: HoldingApiIssuerEntityTsHoldingValidateCreateRequest, options?: AxiosRequestConfig) {
        return HoldingApiFp(this.configuration).issuerEntityTsHoldingValidateCreate(requestParameters.id, requestParameters.issuerEntitiesTsHoldingValidateCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type HoldingDestroyErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: DestroySuccess;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const HoldingDestroyErrorCodes = [
    401,
    422,
];

export type HoldingIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const HoldingIndexErrorCodes = [
    401,
    422,
];

export type HoldingShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const HoldingShowErrorCodes = [
    401,
];

export type HoldingStatusUpdateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const HoldingStatusUpdateErrorCodes = [
    401,
    422,
];

export type HoldingUpdateErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const HoldingUpdateErrorCodes = [
    422,
];

export type IssuerEntityHoldingCreateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 403;
    }

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const IssuerEntityHoldingCreateErrorCodes = [
    401,
    403,
    422,
];

export type IssuerEntityTsHoldingValidateCreateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 403;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const IssuerEntityTsHoldingValidateCreateErrorCodes = [
    401,
    403,
];


