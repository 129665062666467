import styled from 'styled-components';

import { RadioGroup as RadioGroupBase } from '@npm/core/ui/components/atoms/Radio';
import { useBreakpoint } from '@npm/core/ui/utils/style';

export const RadioGroup = styled(RadioGroupBase)`
  ${({ theme }) => useBreakpoint(theme).mobile`
    padding: ${theme.spacing.sm}px ${theme.spacing.md}px;
  `}
`;
