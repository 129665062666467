import { type Rule } from 'antd/lib/form';
import { parsePhoneNumber } from 'libphonenumber-js';
import pluralize from 'pluralize';

type RuleFunction = (...args: unknown[]) => Rule;

interface ValidationRules {
  [key: string]: RuleFunction;
} // Applicable rules: https://ant.design/components/form/#Rule
export const VALIDATION_RULES = {
  required: (label?: string) => {
    return {
      validator: (_, val) => {
        if (typeof val === 'string') {
          if (val.trim().length > 0) {
            return Promise.resolve();
          }
          return Promise.reject();
        }
        if (val != null) {
          return Promise.resolve();
        }
        return Promise.reject();
      },
      message: label ? `${label} is required` : 'This field is required',
    } as Rule;
  },
  min: (min: number): Rule => {
    return { min, message: `This field requires at least ${min} characters.` };
  },
  max: (max: number): Rule => {
    return {
      max,
      message: `This field cannot have more than ${max} characters.`,
    };
  },
  email: (): Rule => {
    return { type: 'email', message: 'Input a valid email address' };
  },
  number: (): Rule => {
    return {
      pattern: /^[0-9]+$/,
      message: 'Please enter a valid number',
    };
  },
  float: (): Rule => {
    return {
      pattern: /^[0-9]+(\.[0-9]{1,6})?$/,
      message:
        'Please enter a valid decimal number with up to 6 decimal places',
    };
  },
  phoneNumber: (): Rule => {
    return {
      message: 'Please enter a valid phone number',
      validator: (_, value: string) => {
        if (!value) {
          return Promise.resolve();
        }
        try {
          const phoneNumber = parsePhoneNumber(value, 'US');
          if (phoneNumber.isValid()) {
            return Promise.resolve();
          }
          return Promise.reject();
        } catch {
          return Promise.reject();
        }
      },
    };
  },
  disclaimerAccepted: (message: string): Rule => {
    return {
      required: true,
      transform: value => value || undefined,
      type: 'boolean',
      message: `${message}`,
    };
  },
  matchSpecificValue: (
    value: string | boolean | number,
    message?: string,
    caseInsensitive?: boolean
  ): Rule => {
    return {
      validator: (_, val) => {
        if (
          (caseInsensitive &&
            val.toString().toLowerCase() !== value.toString().toLowerCase()) ||
          (!caseInsensitive && val !== value)
        ) {
          return Promise.reject(
            new Error(message || `Value must match "${value}"`)
          );
        }

        if (!value) {
          return Promise.reject(new Error('This field is required'));
        }

        return Promise.resolve();
      },
    };
  },
  cannotMatchSpecificValues: <T = string>(
    restrictedValues: T[],
    message?: string,
    caseInsensitive?: boolean
  ): Rule => {
    return {
      validator: (_, val) => {
        if (!val) {
          return Promise.reject(new Error('This field is required'));
        }

        const inputValue = caseInsensitive ? val.toString().toLowerCase() : val;

        console.log('inputValue', inputValue, restrictedValues);

        for (const restrictedValue of restrictedValues) {
          const compareValue =
            caseInsensitive && typeof restrictedValue === 'string'
              ? restrictedValue.toLowerCase()
              : restrictedValue;

          if (inputValue === compareValue) {
            return Promise.reject(
              new Error(message || `Value cannot match "${restrictedValue}"`)
            );
          }
        }

        return Promise.resolve();
      },
    };
  },
  numberMin: (min: number, message?: string): Rule => {
    return {
      validator: (_, val) => {
        if (!val && val !== 0) {
          return Promise.reject(new Error('This field is required'));
        }

        if (Number(val) < min) {
          return Promise.reject(
            new Error(message || `Value must be greater or equal than "${min}"`)
          );
        }

        return Promise.resolve();
      },
    };
  },
  numberMax: (max: number, message?: string): Rule => {
    return {
      validator: (_, val) => {
        if (!val) {
          return Promise.reject(new Error('This field is required'));
        }

        if (Number(val) > max) {
          return Promise.reject(
            new Error(message || `Value must be smaller than "${max}"`)
          );
        }

        return Promise.resolve();
      },
    };
  },
  numberGreaterThan: (min: number, message?: string): Rule => {
    return {
      validator: (_, val) => {
        if (typeof val !== 'number' && !val) {
          return Promise.reject(new Error('This field is required'));
        }

        if (Number(val) > min) {
          return Promise.resolve();
        }

        return Promise.reject(
          new Error(message || `Value must be greater than ${min}`)
        );
      },
    };
  },
  arrayMinLength: (min: number, message?: string): Rule => {
    return {
      validator: (_, val) => {
        if (!val) {
          return Promise.reject(new Error('This field is required'));
        }

        if (val.length < min) {
          return Promise.reject(
            new Error(
              message ||
                `Please select at least ${pluralize('option', min, true)}`
            )
          );
        }

        return Promise.resolve();
      },
    };
  },
} satisfies ValidationRules;
