import React, { type ComponentProps, useMemo } from 'react';
import { type DefaultOptionType } from 'rc-select/lib/Select';

import {
  Select,
  SELECT_PAGE_SIZE_BIG,
  useSelectAsync,
} from '@npm/core/ui/components/atoms/Select';
import { onPopupScroll } from '@npm/core/ui/components/atoms/Select/Select.utils';
import {
  type IssuerEntitiesWorkstationAccountAggregate,
  type WorkstationAccountApiWorkstationAccountIndexRequest,
  useWorkstationAccountIndexInfinite,
  useWorkstationAccountShow,
} from '@npm/data-access';

import { renderAccountOption, SelectedAccount } from '../components';

type SelectHandler = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  option: DefaultOptionType & {
    account: IssuerEntitiesWorkstationAccountAggregate;
  }
) => void;

type Props = Omit<ComponentProps<typeof Select>, 'onSelect'> & {
  issuerEntityId?: number;
  waitForBrokerageFirm?: boolean;
  onSelect?: SelectHandler;
  onCreateAccount?: () => void;
  requestFindVariables?: Pick<
    WorkstationAccountApiWorkstationAccountIndexRequest,
    | 'findRoleSeller'
    | 'findWithMatch'
    | 'findRoleBuyer'
    | 'findPreferredBuyer'
    | 'findTypeEntity'
    | 'findTypeIndividual'
    | 'brokerageFirmId'
  >;
};

export const WorkstationAccountSearch = ({
  issuerEntityId,
  waitForBrokerageFirm,
  requestFindVariables,
  onCreateAccount,
  onSelect,
  value,
  ...props
}: Props) => {
  const [{ searchTerm }, selectAsyncProps] = useSelectAsync();

  const {
    data,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useWorkstationAccountIndexInfinite(
    {
      ...requestFindVariables,
      ...(searchTerm ? { search: searchTerm } : {}),
      issuerEntityId,
      size: SELECT_PAGE_SIZE_BIG,
    },
    {
      onError: Select.onError,
      queryConfig: {
        enabled:
          !!issuerEntityId &&
          (!waitForBrokerageFirm || !!requestFindVariables?.brokerageFirmId),
      },
    }
  );

  const mergedAccounts: IssuerEntitiesWorkstationAccountAggregate[] = useMemo(
    () =>
      data?.pages?.reduce((mergedArray, page) => {
        return mergedArray.concat(page.workstation_accounts);
      }, []),
    [data]
  );

  // fetch selected account if there is value and it is not in the list or list is just fetching
  const shouldFetchSelectedAccount = !!(
    value &&
    (!mergedAccounts || !mergedAccounts.find(account => account?.id === value))
  );

  const { data: selectedAccountData } = useWorkstationAccountShow(
    { ...requestFindVariables, issuerEntityId, id: value },
    { queryConfig: { enabled: shouldFetchSelectedAccount } }
  );

  const options = useMemo(() => {
    if (!mergedAccounts) return null;

    let accounts = mergedAccounts;

    if (
      value &&
      !mergedAccounts.find(account => account?.id === value) &&
      selectedAccountData
    ) {
      accounts = [selectedAccountData, ...mergedAccounts];
    }

    return accounts.map(account => ({
      label: (
        <SelectedAccount
          accountName={account?.name}
          id={account?.external_id}
          brokerageFirmName={account?.brokerage_firm?.name}
        />
      ),
      value: account?.id,
      account,
    }));
  }, [mergedAccounts, selectedAccountData, value]);

  const shouldDisplayValue =
    options?.length &&
    !!value &&
    options.find(option => option.value === value);

  return (
    <Select
      variant="search"
      showSearch
      onPopupScroll={e =>
        onPopupScroll(e, hasNextPage && !isFetchingNextPage && fetchNextPage)
      }
      loading={isLoading}
      infiniteLoading={isFetchingNextPage}
      error={error}
      onSelect={value =>
        // we are using custom option object because we need to pass account object to onSelect handler
        onSelect(
          value,
          options.find(o => o.value === value)
        )
      }
      placeholder={
        options ? `All (${data.pages[0].pagination.total_records})` : 'All'
      }
      value={shouldDisplayValue ? value : null}
      dropdownExtraProps={
        onCreateAccount
          ? {
              title: 'Create New Account',
              onClick: onCreateAccount,
              icon: 'external-link',
              placement: 'top',
            }
          : undefined
      }
      {...props}
      {...selectAsyncProps}
    >
      {options?.map(renderAccountOption)}
    </Select>
  );
};
