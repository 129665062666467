import {
  type SubmissionApiSecondmarketSubmissionIndexRequest,
  CbOrderItemState,
  CbSecondMarketOrderSort,
} from '@npm/data-access';

import {
  EXPIRING_ORDERS_STATE,
  getExpiringOrderDate,
} from '../../../client-orders/expiringOrders';

import { type InvestorOrdersTab } from './InvestorOrdersList.types';

const live = CbOrderItemState.items.live_in_marketplace;
const underReview = CbOrderItemState.items.not_posted_to_marketplace;
const incomplete = CbOrderItemState.items.incomplete;

export const getEnhancedVariables = (
  tab: InvestorOrdersTab,
  variables: SubmissionApiSecondmarketSubmissionIndexRequest
) => {
  switch (tab) {
    case 'all':
      return variables;
    case 'live':
      return {
        ...variables,
        orderItemState: [live],
      };
    case 'not_posted_to_marketplace':
      return {
        ...variables,
        orderItemState: [underReview],
      };
    case 'incomplete':
      return {
        ...variables,
        orderItemState: [incomplete],
      };
    case 'expiring':
      return {
        ...variables,
        orderItemState: EXPIRING_ORDERS_STATE,
        expiringBefore: getExpiringOrderDate(),
        findWithoutLiveNegotiation: true,
        sort: CbSecondMarketOrderSort.items.expiry_ascending,
      };
    default:
      return variables;
  }
};
