import React from 'react';
import { useTheme } from 'styled-components';

import { Flex, Padding } from '../../../atoms/common';
import { Icon } from '../../../atoms/Icon';
import { Text } from '../../../atoms/Typography';

export interface ListItemProps {
  title: string;
  gap?: 'lg' | 'md' | 'sm' | 'xs';
  textProps?: Partial<React.ComponentProps<typeof Text>>;
  style?: React.CSSProperties;
}

export const ListItem = ({
  title,
  gap = 'md',
  textProps,
  style,
}: ListItemProps) => {
  const theme = useTheme();

  return (
    <Padding all={gap} style={style}>
      <Flex direction="row" align="center" gap={gap}>
        <Icon
          name="check"
          color={theme.color.success.typography.primary}
          size="xxs"
        />
        <Text size="sm" {...textProps}>
          {title}
        </Text>
      </Flex>
    </Padding>
  );
};
