import { type Codebook, type CodebookOrString } from '../generated';

import { type CodebookItem } from './codebooks.types';

const convertCodebookToString = (
  codebook: CodebookOrString | CodebookOrString[]
) => {
  // double check if item is really codebook
  return (codebook as Codebook)
    ? codebook.code
      ? codebook.code
      : codebook
    : codebook;
};

export const unpackCodebook = (
  codebook: CodebookOrString | CodebookOrString[]
) => {
  if (Array.isArray(codebook)) {
    return codebook.map(c => convertCodebookToString(c));
  }

  return convertCodebookToString(codebook);
};

export const unpackCodebooks = <T extends {}>(data: T, fields: (keyof T)[]) => {
  const result = { ...data };
  fields.forEach(val => {
    result[val] = unpackCodebook(result[val]);
  });
  return result;
};

export const mapCbItemsForSelectOptions = (items: CodebookItem[]) => {
  return items.map(item => ({
    label: item.name,
    value: item.code,
  }));
};

export const getCbAttribute = (item: CodebookItem | undefined, name: string) =>
  item?.attributes?.find(q => q.name === name)?.value;
