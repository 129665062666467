import { withErrorBoundary } from 'react-error-boundary';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { ErrorSkeleton } from '@npm/core/ui/components/atoms/ErrorSkeleton';
import { type VenusApi } from '@npm/data-access';

import { PrimaryRoundsChart } from './components/PrimaryRoundsChart';
import { PrimaryRoundsTable } from './components/PrimaryRoundsTable';

type Props = {
  data: VenusApi.PrimaryOcfValuationIndex;
  venusCompanyId: number | undefined;
  issuerEntityId: number | undefined;
};

export const PrimaryRounds = withErrorBoundary(
  ({ data, venusCompanyId, issuerEntityId }: Props) => {
    return (
      <Flex direction="column" gap="md">
        {!!data.primary_rounds.length && (
          <PrimaryRoundsChart data={data.primary_rounds} />
        )}

        <PrimaryRoundsTable
          items={data.primary_rounds}
          venusCompanyId={venusCompanyId}
          issuerEntityId={issuerEntityId}
        />
      </Flex>
    );
  },
  {
    FallbackComponent: ErrorSkeleton,
  }
);
