/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AdvisorReferralExportApi,
  AdvisorReferralExportIndexErrorCodes,
  AdvisorReferralExportIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AdvisorReferralExportApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AdvisorReferralExportIndexMethod = AdvisorReferralExportApi['advisorReferralExportIndex'];
export type AdvisorReferralExportIndexResponseType = MethodResult<AdvisorReferralExportIndexMethod>;

export const useAdvisorReferralExportIndex = (
  variables: Parameters<AdvisorReferralExportIndexMethod>[0],
  config?: UseQueryConfig<
    AdvisorReferralExportIndexMethod,
    AdvisorReferralExportIndexErrorTypes
  >
) => {
  return useQuery<
    AdvisorReferralExportIndexMethod,
    AdvisorReferralExportIndexErrorTypes
  >(
    (...args) => api.advisorReferralExportIndex(...args),
    AdvisorReferralExportIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AdvisorReferralExportIndex'],
        ...apiQueryConfig.AdvisorReferralExportIndex,
        ...config?.queryConfig
      },
    },
    'AdvisorReferralExportIndex',
  );
};

export const useAdvisorReferralExportIndexInfinite = (
  variables: Parameters<AdvisorReferralExportIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AdvisorReferralExportIndexMethod,
    AdvisorReferralExportIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AdvisorReferralExportIndexMethod,
    AdvisorReferralExportIndexErrorTypes
  >(
    (...args) => api.advisorReferralExportIndex(...args),
    AdvisorReferralExportIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AdvisorReferralExportIndex', 'AdvisorReferralExportIndexInfinite'],
        ...apiQueryConfig.AdvisorReferralExportIndex,
        ...config?.queryConfig,
      },
    },
    'AdvisorReferralExportIndex',
  );
};

export const useAdvisorReferralExportIndexLazy = (baseOptions?: {
  variables?: Parameters<AdvisorReferralExportIndexMethod>[0],
  config?: UseQueryConfig<
    AdvisorReferralExportIndexMethod,
    AdvisorReferralExportIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AdvisorReferralExportIndexMethod,
    AdvisorReferralExportIndexErrorTypes
  >(
    (...args) => api.advisorReferralExportIndex(...args),
    AdvisorReferralExportIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AdvisorReferralExportIndex'],
        ...apiQueryConfig.AdvisorReferralExportIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AdvisorReferralExportIndex',
  );
};

