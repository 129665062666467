/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HashMapValue } from '../model';
/**
 * CalendlyWebhookApi - axios parameter creator
 * @export
 */
export const CalendlyWebhookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary saves marketing data for 30 secs
         * @param {string} calendlyWebhookSignature 
         * @param {{ [key: string]: HashMapValue; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendlyWebhookCallback: async (calendlyWebhookSignature: string, requestBody: { [key: string]: HashMapValue; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendlyWebhookSignature' is not null or undefined
            assertParamExists('calendlyWebhookCallback', 'calendlyWebhookSignature', calendlyWebhookSignature)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('calendlyWebhookCallback', 'requestBody', requestBody)
            const localVarPath = changeUrl(`/api/public/calendly-events`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (calendlyWebhookSignature !== undefined && calendlyWebhookSignature !== null) {
                localVarHeaderParameter['Calendly-Webhook-Signature'] = String(calendlyWebhookSignature);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendlyWebhookApi - functional programming interface
 * @export
 */
export const CalendlyWebhookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendlyWebhookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary saves marketing data for 30 secs
         * @param {string} calendlyWebhookSignature 
         * @param {{ [key: string]: HashMapValue; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendlyWebhookCallback(calendlyWebhookSignature: string, requestBody: { [key: string]: HashMapValue; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: HashMapValue; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendlyWebhookCallback(calendlyWebhookSignature, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalendlyWebhookApi - factory interface
 * @export
 */
export const CalendlyWebhookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendlyWebhookApiFp(configuration)
    return {
        /**
         * 
         * @summary saves marketing data for 30 secs
         * @param {string} calendlyWebhookSignature 
         * @param {{ [key: string]: HashMapValue; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendlyWebhookCallback(calendlyWebhookSignature: string, requestBody: { [key: string]: HashMapValue; }, options?: any): AxiosPromise<{ [key: string]: HashMapValue; }> {
            return localVarFp.calendlyWebhookCallback(calendlyWebhookSignature, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for calendlyWebhookCallback operation in CalendlyWebhookApi.
 * @export
 * @interface CalendlyWebhookApiCalendlyWebhookCallbackRequest
 */
export interface CalendlyWebhookApiCalendlyWebhookCallbackRequest {
    /**
     * 
     * @type {string}
     * @memberof CalendlyWebhookApiCalendlyWebhookCallback
     */
    readonly calendlyWebhookSignature: string

    /**
     * 
     * @type {{ [key: string]: HashMapValue; }}
     * @memberof CalendlyWebhookApiCalendlyWebhookCallback
     */
    readonly requestBody: { [key: string]: HashMapValue; }
}

/**
 * CalendlyWebhookApi - object-oriented interface
 * @export
 * @class CalendlyWebhookApi
 * @extends {BaseAPI}
 */
export class CalendlyWebhookApi extends BaseAPI {
    /**
     * 
     * @summary saves marketing data for 30 secs
     * @param {CalendlyWebhookApiCalendlyWebhookCallbackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendlyWebhookApi
     */
    public calendlyWebhookCallback(requestParameters: CalendlyWebhookApiCalendlyWebhookCallbackRequest, options?: AxiosRequestConfig) {
        return CalendlyWebhookApiFp(this.configuration).calendlyWebhookCallback(requestParameters.calendlyWebhookSignature, requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}

export type CalendlyWebhookCallbackErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CalendlyWebhookCallbackErrorCodes = [
];


