import { Space } from 'antd';
import { useTheme } from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { Card } from '@npm/core/ui/components/molecules/Card';
import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';
import { type VenusApi } from '@npm/data-access';

import {
  UnlockPremiumContextProvider,
  useUnlockPremium,
} from '../../../../auth/user/components';
import { usePremiumPlan } from '../../../../auth/user/role';
import { CorporateProfileSkeleton } from '../skeletons';

import * as S from './CompanyReport.styles';

type Props = {
  companyProfile?: VenusApi.CompanyProfile;
  isLoading?: boolean;
};

export const CompanyReport = ({ companyProfile, isLoading }: Props) => {
  const theme = useTheme();
  const { isPremium } = usePremiumPlan();

  const unlockPremiumAccount = useUnlockPremium();

  if (isLoading)
    return (
      <Card>
        <CorporateProfileSkeleton />
      </Card>
    );

  if (!companyProfile.sacra_url) return null;

  return (
    <Card>
      <UnlockPremiumContextProvider>
        <Space direction="vertical" size="middle">
          <Space direction="horizontal">
            {isPremium ? (
              <Label
                variant="info"
                style={{ padding: `${theme.spacing.xs}px` }}
                icon={
                  <Icon
                    name="lightning"
                    color={theme.color.info.typography.primary}
                  />
                }
              >
                New
              </Label>
            ) : (
              <Label
                variant="warning"
                style={{
                  height: theme.spacing.xxl,
                  width: theme.spacing.xxl,
                }}
              >
                <Icon
                  name="lock"
                  size="md"
                  color={theme.color.warning.typography.primary}
                />
              </Label>
            )}

            <Heading variant="h1" as="h3">
              Equity Research Report
            </Heading>
          </Space>

          <Space direction="horizontal">
            <Text size="default" colorVariant="tertiary">
              Last Updated
            </Text>

            <Text>
              {formatDate(companyProfile.sacra_date, {
                dateFormat: DATE_FORMATS.DATE,
              })}
            </Text>

            <Text
              size="default"
              colorVariant="tertiary"
              style={{ marginLeft: '-2px' }}
            >
              • Powered by
            </Text>

            <S.LinkProvider href="https://sacra.com/" target="_blank">
              Sacra
            </S.LinkProvider>
          </Space>

          {isPremium && <Text>{companyProfile.sacra_text}</Text>}

          {isPremium ? (
            <Button
              iconPosition="right"
              size="lg"
              icon={<Icon name={'external-link'} size={'sm'} />}
              target="_blank"
              href={companyProfile.sacra_url}
              style={{ maxWidth: '140px' }}
            >
              Access Report
            </Button>
          ) : (
            <Button
              iconPosition="right"
              size="lg"
              icon={<Icon name="chevron-right" size="xs" />}
              style={{ maxWidth: '180px' }}
              onClick={unlockPremiumAccount}
            >
              Upgrade to Premium
            </Button>
          )}
        </Space>
      </UnlockPremiumContextProvider>
    </Card>
  );
};
