import React, { type FC, type ReactNode } from 'react';

import type { IconSize } from '@npm/utils';

import { useBreakpoints } from '../../../hooks/useBreakpoints';
import { getCurrencySymbol } from '../../../utils/formatters';
import { Flex } from '../../atoms/common';
import { Icon } from '../../atoms/Icon';
import { Label } from '../../atoms/Label';
import { Tooltip } from '../../atoms/Tooltip';
import { Heading, Text, TextCurrency } from '../../atoms/Typography';

import * as S from './StatisticCard.styles';

export type StatsVariant = 'success' | 'info' | 'error' | 'warning' | 'general';

export type StatsCardProps = {
  title: string;
  value: number | string | null | undefined | ReactNode;
  icon?: ReactNode;
  compactFormat?: boolean;
  allowFractionDigits?: boolean;
  variant?: StatsVariant;
  currency?: string;
  currencyAsSymbol?: boolean;
  loading?: boolean;
  badge?: {
    title: string;
    tooltip?: string;
  };
  tooltip?: {
    text: string;
    iconSize?: IconSize;
  };
  className?: string;
  withTextWrap?: boolean;
  outlined?: boolean;
};

const Skeleton = () => (
  <>
    <S.SkeletonAvatar shape="square" />
    <S.SkeletonBase
      title={false}
      paragraph={{ rows: 2, width: ['50%', '25%'] }}
    />
  </>
);

// eslint-disable-next-line react/no-multi-comp
export const StatisticCard: FC<StatsCardProps> = ({
  loading,
  variant,
  icon,
  title,
  value,
  badge,
  currency,
  currencyAsSymbol,
  compactFormat,
  allowFractionDigits,
  className,
  tooltip,
  withTextWrap = true,
  outlined,
}) => {
  const { isMobile } = useBreakpoints();

  const renderValue = () => {
    if (value === undefined || value === null) return '--';

    if (typeof value === 'string') {
      return value;
    }

    const number = Number(value);

    const options: Intl.NumberFormatOptions = {
      notation: compactFormat ? 'compact' : 'standard',
      minimumFractionDigits: allowFractionDigits && number % 1 !== 0 ? 2 : 0,
      maximumFractionDigits: allowFractionDigits ? 2 : 0,
    };

    return new Intl.NumberFormat('en-US', options).format(number);
  };

  return (
    <S.Card
      outlined={outlined}
      noContentPadding
      fullHeight
      className={className}
    >
      <S.Container>
        {loading ? (
          <Skeleton />
        ) : (
          <>
            {icon && <S.IconWrapper $variant={variant}>{icon}</S.IconWrapper>}
            <div>
              <S.TitleContainer $withTextWrap={withTextWrap}>
                <Text size="sm">{title}</Text>
                {tooltip && (
                  <Tooltip title={tooltip?.text}>
                    <Flex justify="center" align="center">
                      <Icon
                        name="info-circle"
                        size={tooltip?.iconSize ?? 'xs'}
                      />
                    </Flex>
                  </Tooltip>
                )}
              </S.TitleContainer>
              <Flex align="center">
                <Flex align="baseline">
                  {React.isValidElement(value) ? (
                    value
                  ) : (
                    <Heading variant="h1" as="span">
                      {!!currency &&
                        currencyAsSymbol &&
                        getCurrencySymbol(currency)}
                      {renderValue()}
                    </Heading>
                  )}

                  {!!currency && !currencyAsSymbol && (
                    <TextCurrency marginLeft={isMobile ? 2 : 4}>
                      {currency}
                    </TextCurrency>
                  )}
                </Flex>
                {badge && (
                  <S.Badge>
                    <Tooltip title={badge?.tooltip}>
                      <Label
                        icon={badge.tooltip && <Icon name="info-circle" />}
                        variant="info"
                        round
                      >
                        {badge.title}
                      </Label>
                    </Tooltip>
                  </S.Badge>
                )}
              </Flex>
            </div>
          </>
        )}
      </S.Container>
    </S.Card>
  );
};
