import { Codebooks } from './codebooks.types';

const items = {
  last_week: 'last_week',
  last_month: 'last_month',
  last_3_months: 'last_3_months',
  last_6_months: 'last_6_months',
  all: 'all',
} as const;

export type TimeRangeKey = keyof typeof items;

export const CbTimeRange = {
  code: Codebooks.TIME_RANGE,
  items: items,
};
