import { createContext, useContext, useMemo, useRef } from 'react';

import { CbNotificationState, useNotificationIndex } from '@npm/data-access';

import { useUserContextStore } from '../../auth/user/context';
import { useAreNotificationsEnabled } from '../hooks';

import { useNewNotificationHandler } from './useNewNotificationHandler';

export const NotificationContext = createContext<{
  unreadNotifications: number;
}>(null);

type Props = {
  children?: React.ReactElement;
};

/**
 * Notification provider has 2 functionalities:
 * * Provide number of unread notifications
 * * Handle new notifications
 * @param children
 * @constructor
 */
export const NotificationProvider = ({ children }: Props) => {
  const currentUser = useUserContextStore(state => state.user);
  const areNotificationsEnabled = useAreNotificationsEnabled();

  // Loading unread notifications count. There is no need to have the items, therefore we are looking only for the pagination total_records
  const { data } = useNotificationIndex(
    {
      notificationState: CbNotificationState.items.unread,
      page: 1,
      size: 0,
    },
    {
      queryConfig: {
        enabled: !!currentUser && areNotificationsEnabled,
        keepPreviousData: true,
      },
      onError: () => {
        // ignoring error, as we don't need to handle it
      },
    }
  );

  useNewNotificationHandler();

  const value = useMemo(() => {
    return {
      unreadNotifications: data?.pagination?.total_records ?? 0,
    };
  }, [data]);

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

export const withNotificationContext = <T,>(Component: React.ComponentType) => {
  return (props: T) => (
    <NotificationProvider>
      <Component {...props} />
    </NotificationProvider>
  );
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      'useNotificationContext must be used within a NotificationProvider'
    );
  }
  return context;
};
