import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { PercentageChange } from '@npm/core/ui/components/molecules/PercentageChange';

type Props = {
  price: number;
  change?: number;
  multiplyChange?: boolean;
  impliedValuation?: number;
  sizeRange?: string;
  align?: 'flex-start' | 'flex-end';
  isMuted?: boolean;
};

export const CompanyOverviewPriceDisplay = ({
  price,
  change,
  impliedValuation,
  sizeRange,
  multiplyChange = false,
  align = 'flex-start',
  isMuted = false,
}: Props) => {
  return (
    <Flex direction="column" align={align}>
      <Flex>
        <Text.Price
          value={price}
          formatOptions={{
            long: true,
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }}
          colorVariant={isMuted ? 'tertiary' : 'primary'}
        />
        {!!change && (
          <PercentageChange
            change={multiplyChange ? change * 100 : change}
            maximumFractionDigits={0}
            isMuted={isMuted}
          />
        )}
      </Flex>
      {!!impliedValuation && (
        <Text size="xs" colorVariant={isMuted ? 'muted' : 'tertiary'} as="span">
          Implied Val.{' '}
          <Text.Price
            size="xs"
            value={impliedValuation}
            compact
            colorVariant={isMuted ? 'tertiary' : 'primary'}
          />
        </Text>
      )}
      {!!sizeRange && (
        <Flex align="center" gap="xs">
          <Text size="xs" colorVariant="secondary">
            Size Range
          </Text>
          <Text size="xs" colorVariant="primary">
            {sizeRange}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
